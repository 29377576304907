import * as React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { Col, Container, Row } from 'reactstrap';
import { Info } from 'julius-frontend-components';
import { connect } from 'react-redux';
import { styled } from 'react-free-style';
import { withRouter } from 'react-router-dom';

import CoverageRecommendations from '../../components/coverage-recommendations';
import CoverageReport from '../../components/coverage-report';

class Coverage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { styles, request } = this.props;
    const coverage = request.coverage;

    return (
      <Container className={styles.container}>
        <Row className={styles.row}>
          <Col className={styles.col}>{coverage && <CoverageRecommendations assignment={coverage} />}</Col>
          <Col className={styles.col}>
            {coverage && (
              <Card className={styles.card}>
                <CardBody>
                  <CardTitle className={styles.cardTitle}>Reader</CardTitle>
                  <Info name="Name">{coverage.reader && coverage.reader.name}</Info>
                  <Info name="Email">{coverage.reader && coverage.reader.email}</Info>
                  <Info name="Completed">{coverage.completed && new Date(coverage.completed).toLocaleString()}</Info>
                </CardBody>
              </Card>
            )}
            {coverage && <CoverageReport assignment={coverage} />}
          </Col>
        </Row>
      </Container>
    );
  }
}

const withStyles = styled({
  container: { margin: '0px', width: '100%' },
  row: { margin: '0px' },
  col: { margin: '0px' },
  card: {
    minWidth: 275,
    marginBottom: '10px',
  },
  cardTitle: {
    fontFamily: 'Gotham, sans-serif',
    fontSize: '12px',
    textTransform: 'uppercase',
  },
});

const withState = connect(state => {
  return {};
});

export default withRouter(withState(withStyles(Coverage)));
