import * as React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { styled } from 'react-free-style';
import { withRouter, Link } from 'react-router-dom';

import { EditCard, Info, Input } from 'julius-frontend-components';

import * as icons from '../../../styles/icons';

class Assignment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      readerName: '',
      readerEmail: '',
    };
  }

  componentWillMount() {
    this.loadDetails();
  }

  async loadDetails() {
    const { dispatch } = this.props;
    //dispatch(something);
  }

  render() {
    const {
      styles,
      request,
      tempRequest,
      onAssignmentsSave,
      onAssignmentsCancel,
      onChange,
      onCoverageReview,
    } = this.props;

    const { readerName, readerEmail } = this.state;
    const canAddReader = readerEmail.trim() !== '' && readerName.trim() != '';

    return (
      <Container className={`m-0 w-100`}>
        <Row className={`m-0 w-50`}>
          <Col className={`m-0`}>
            <EditCard
              title="Readers"
              canEdit={true}
              canCancel={request.assignments != null && request.assignments.length > 0}
              saveChanges={null}
              className={styles.card}
              isEditing={request.assignments == null || request.assignments.length === 0}
              onSave={() => {
                onAssignmentsSave();
              }}
              onCancel={() => {
                onAssignmentsCancel();
              }}
            >
              <div mode="Edit">
                <Input
                  title="Full Name"
                  onChange={text => {
                    this.setState({ readerName: text });
                  }}
                  value={readerName}
                />

                <Input
                  title="Email Address"
                  onChange={text => {
                    this.setState({ readerEmail: text });
                  }}
                  value={readerEmail}
                />

                <div style={{ width: '100%', textAlign: 'right', marginTop: '5px' }}>
                  <Button
                    size="sm"
                    disabled={!canAddReader}
                    onClick={() => {
                      if (tempRequest.assignments == null) {
                        tempRequest.assignments = [];
                      }
                      tempRequest.assignments.push({ reader: { name: readerName, email: readerEmail } });
                      onChange();
                      this.setState({ readerName: '', readerEmail: '' });
                    }}
                  >
                    Add
                  </Button>
                </div>
                <div style={{ marginTop: '10px' }}>
                  {tempRequest.assignments &&
                    tempRequest.assignments.map((assignment, i) => {
                      const url = `/reviews/${assignment.token}`;
                      return (
                        <Info
                          name={
                            <span style={{ marginRight: '10px' }}>
                              <Link
                                className={styles.link}
                                to={`${url}?referer=${encodeURIComponent(this.props.location.search)}`}
                              >
                                {`${assignment.reader.name} (${assignment.reader.email})`}
                              </Link>
                            </span>
                          }
                        >
                          {assignment.completed && (
                            <div>
                              <a
                                style={{
                                  color: '#4A4A4A',
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                  marginLeft: '10px',
                                }}
                                onClick={() => {
                                  onCoverageReview(assignment);
                                }}
                              >
                                review
                              </a>
                              <br />
                            </div>
                          )}
                          <a
                            style={{
                              cursor: 'pointer',
                              marginRight: '10px',
                            }}
                            onClick={() => {
                              tempRequest.assignments.splice(i, 1);
                              onChange();
                            }}
                          >
                            <i className={styles.crossBlackIcon} />
                          </a>
                        </Info>
                      );
                    })}
                </div>
              </div>
              <div mode="Info">
                {request.assignments &&
                  request.assignments.map((assignment, i) => {
                    const url = `/reviews/${assignment.token}`;
                    return (
                      <Info
                        name={
                          <span style={{ marginRight: '10px' }}>
                            <Link
                              className={styles.link}
                              to={`${url}?referer=${encodeURIComponent(this.props.location.search)}`}
                            >
                              {`${assignment.reader.name} (${assignment.reader.email})`}
                            </Link>
                          </span>
                        }
                      >
                        {assignment.completed && (
                          <div>
                            <a
                              style={{
                                color: '#4A4A4A',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                                marginLeft: '10px',
                              }}
                              onClick={() => {
                                onCoverageReview(assignment);
                              }}
                            >
                              review
                            </a>
                            <br />
                          </div>
                        )}
                      </Info>
                    );
                  })}
              </div>
            </EditCard>
          </Col>
        </Row>
      </Container>
    );
  }
}

const withStyles = styled({
  crossBlackIcon: icons.crossBlack,
  card: {
    minWidth: 275,
    marginBottom: '10px',
  },
  link: { color: '#4A4A4A', textDecoration: 'underline' },
});

const withState = connect(state => {
  return {};
});

export default withRouter(withState(withStyles(Assignment)));
