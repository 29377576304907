import * as React from 'react';
import { Button } from 'reactstrap';

export default class ButtonSelector extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { values, targetObject, targetField } = this.props;

    return values.map(value => {
      return (
        <Button
          size="sm"
          style={{ marginLeft: '2px' }}
          outline={targetObject[targetField] !== value}
          onClick={() => {
            targetObject[targetField] = value;
            this.setState({});
          }}
        >
          {value}
        </Button>
      );
    });
  }
}
