import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { styled } from 'react-free-style';
//import { Row, Card, CardText, CardBody, CardTitle, CardSubtitle, Button, Table } from 'reactstrap';

import { Spinner } from 'julius-frontend-components';
import { normalizeDesks } from 'julius-frontend-store';

class Reports extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      type: '',
    };
  }

  componentWillMount() {
    this.loadList();
  }

  async loadList() {
    const { dispatch } = this.props;
    //dispatch(something);
  }

  render() {
    const { styles } = this.props;

    return <div className="w-50">Coming Soon</div>;
  }
}

const withStyles = styled({
  card: {
    minWidth: 275,
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const withState = connect(state => {
  return {};
});

export default withRouter(withState(withStyles(Reports)));
