import * as React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { styled, helpers } from 'react-free-style';
//import { Row, Card, CardText, CardBody, CardTitle, CardSubtitle, Button, Table } from 'reactstrap';
import * as colors from '../../../styles/colors';
import * as elements from '../../../styles/elements';
import * as icons from '../../../styles/icons';
import { Spinner } from 'julius-frontend-components';
import { hasAccess } from '../../../support/auth';
class Scripts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      type: '',
    };
  }

  componentWillMount() {
    this.loadDetails();
  }

  async loadDetails() {
    const { dispatch } = this.props;
    //dispatch(something);
  }

  render() {
    const { styles } = this.props;
    const list = [];
    return <div>Coming Soon!</div>;
  }
}

const withStyles = styled({
  card: {
    minWidth: 275,
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    color: colors.contentColor,
    fontSize: 12,
    textTransform: 'uppercase',
  },
  thText: {
    textAlign: 'left',
    padding: 10,
  },
  scriptButton: helpers.merge(elements.button, elements.actionable, {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderColor: '#000',
    paddingTop: '0px',
    paddingBottom: '0px',
    height: '30px',
  }),
  scriptButtonIcon: helpers.merge(
    {
      marginRight: 10,
    },
    icons.plus
  ),
  buttonText: {
    width: '60px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const withState = connect(state => {
  return {};
});

export default withRouter(withState(withStyles(Scripts)));
