import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { styled } from 'react-free-style';
//import { Row, Card, CardText, CardBody, CardTitle, CardSubtitle, Button, Table } from 'reactstrap';

import { Spinner } from 'julius-frontend-components';
import { normalizeDesks } from 'julius-frontend-store';
import { hasAccess, getScopes } from '../support/auth';

export default class AuthView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      type: '',
    };
  }
  render() {
    const { feature, view } = this.props;
    if (hasAccess(feature)) {
      return React.cloneElement(view, { feature, scopes: getScopes(feature), ...this.props });
    } else {
      return (
        <div>
          <h1>Forbidden</h1>Contact an administor to access to this feature.
        </div>
      );
    }
  }
}
