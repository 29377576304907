import * as React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { styled, helpers } from 'react-free-style';
import * as colors from '../../../styles/colors';
import * as elements from '../../../styles/elements';
import * as icons from '../../../styles/icons';
import { Spinner } from 'julius-frontend-components';
import { hasAccess } from '../../../support/auth';
import * as sizes from '../../../styles/sizes';
import { parse } from 'querystring';
import RolesView from './roles';
import CoverageView from './coverage';
import { getReaderResources, assignmentUpdate } from 'julius-frontend-store';
import { Branding, Search, Avatar } from 'julius-frontend-components';
import { Container, Row, Col, Button, Form, FormGroup, Label, ListGroup, ListGroupItem } from 'reactstrap';
import AssignmentView from './assignment';

const tabs = [
  { id: 'assignment', title: 'Assignment', view: <AssignmentView /> },
  { id: 'coverage', title: 'Coverage', view: <CoverageView /> },
  { id: 'roles', title: 'Roles', view: <RolesView /> },
];

class ReviewDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      resources: [],
      assignment: {
        script: { score: null, character: null, tone: null, dialog: null, visual: null },
        story: { score: null, originality: null, structure: null, plotting: null, commerciality: null },
        writer: { score: null, comments: null },
        budget: null,
        genre: null,
        period: null,
        location: null,
        roles: [],
        reader: {},
      },
    };
    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {
    this.loadDetails();
  }

  async loadDetails() {
    const {
      dispatch,
      match: { params },
    } = this.props;
    dispatch(getReaderResources(params.token)).then(result => {
      const assignment = Object.assign({}, this.state.assignment);
      assignment.token = params.token;
      this.setState({ resources: result.body, assignment });
    });
  }
  onChange(coverage) {
    coverage != null ? this.setState({ coverage }) : this.setState({});
  }
  getActiveTab() {
    const { tab } = this.state;
    const qs = parse(this.props.location.search.substr(1));
    const _tab = tabs.find(x => {
      return (qs.tab || 'assignment') === x.id;
    });
    return _tab;
  }
  render() {
    const qs = parse(this.props.location.search.substr(1));
    const referrer = qs.referer;
    const { styles, scopes, dispatch } = this.props;
    const { resources, assignment } = this.state;
    const activeTab = this.getActiveTab();
    const view = React.cloneElement(activeTab.view, { resources, assignment, onChange: this.onChange });
    return (
      <div className="row m-0 p-0">
        <div className="container-fluid" style={{ padding: '0px' }}>
          <Branding text="Reader Portal" />
          <br />
          <Container style={{ margin: '0px', paddingRight: '0px' }} className="col-md-12">
            <Row style={{ width: '100%' }}>
              <Col style={{ padding: '0px' }}>
                {assignment.completed == null ? (
                  <div className={styles.container}>
                    <div className={styles.header} />
                    {assignment.started != null && (
                      <div className={styles.content}>
                        <div className={styles.menu}>{this.renderTabs(styles, activeTab, referrer)}</div>
                      </div>
                    )}
                    <div className={styles.body}>
                      <div className={styles.content}>{view}</div>
                    </div>
                    <div className={styles.body}>
                      <div className={styles.content}>
                        <Container className={styles.container}>
                          <Row className={styles.row}>
                            <Col className={styles.col}>
                              {assignment.started == null ? (
                                <Button
                                  className="float-right"
                                  style={{ margin: '10px' }}
                                  disabled={assignment.reader.name == null || assignment.reader.email == null}
                                  onClick={() => {
                                    assignment.started = new Date();
                                    const url = `?tab=coverage${
                                      referrer ? '&referer=' + encodeURIComponent(referrer) : ''
                                    }`;
                                    this.props.history.push(url);
                                  }}
                                >
                                  Start Review >>
                                </Button>
                              ) : (
                                <Button
                                  className="float-right"
                                  style={{ margin: '10px' }}
                                  disabled={
                                    assignment.script.score == null ||
                                    assignment.story.score == null ||
                                    assignment.writer.score == null
                                  }
                                  onClick={() => {
                                    assignment.completed = new Date();
                                    dispatch(assignmentUpdate(assignment)).then(() => {
                                      const url = `?tab=assignment${
                                        referrer ? '&referer=' + encodeURIComponent(referrer) : ''
                                      }`;
                                      this.props.history.push(url);
                                    });
                                  }}
                                >
                                  Submit Review
                                </Button>
                              )}

                              <br />
                              <br />
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div style={{ padding: '50px' }}>Coverage Report Submitted</div>
                )}
                {referrer && (
                  <span>
                    <br />
                    <Link style={{ padding: '50px' }} className={styles.link} to={`/${referrer}`}>
                      Demo Mode: Back to Coverage Request
                    </Link>
                  </span>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
  renderTabs(styles, activeTab, referrer) {
    return tabs.map(tab => (
      <Link
        key={tab.title}
        to={{
          search: `?tab=${encodeURIComponent(tab.id)}${referrer ? '&referer=' + encodeURIComponent(referrer) : ''}`,
        }}
        className={`${styles.menuItem} ${activeTab.id === tab.id ? styles.activeItem : ''}`}
      >
        {tab.title}
      </Link>
    ));
  }
}

const withStyles = styled({
  content: helpers.merge(sizes.container, {
    display: 'flex',
    flexDirection: 'column',
  }),
  container: { margin: '0px', width: '100%' },
  row: { margin: '0px' },
  col: { margin: '0px', paddingRight: '20px' },
  button: { padding: '20px' },
  menu: helpers.merge(elements.menu, {
    marginBottom: 20,
  }),
  menuItem: elements.menuItem,
  activeItem: elements.activeMenuItem,
  editIcon: icons.pencil,
});

const withState = connect(state => {
  const { scripts } = state;
  return { scripts };
});

export default withRouter(withState(withStyles(ReviewDetails)));
