import * as React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { styled, helpers } from 'react-free-style';
//import { Row, Card, CardText, CardBody, CardTitle, CardSubtitle, Button, Table } from 'reactstrap';
import * as colors from '../../styles/colors';
import * as elements from '../../styles/elements';
import * as icons from '../../styles/icons';
import { Spinner } from 'julius-frontend-components';
import { hasAccess, getScopes } from '../../support/auth';
import { coverageRequestList } from 'julius-frontend-store';
class CoverageAssignments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      type: '',
    };
  }

  componentWillMount() {
    this.loadList();
  }

  async loadList() {
    const { dispatch } = this.props;
    dispatch(coverageRequestList());
  }

  render() {
    const { styles, scopes, scripts } = this.props;
    const coverageRequests = scripts.coverageRequests;
    //TODO: filtering for mocking out server response that handles scoping
    const list =
      coverageRequests == null || coverageRequests.length == null
        ? []
        : coverageRequests.filter(cr => {
            return cr.completed == null && (cr.assigned != null || cr.assigned != null);
          });

    return (
      <div>
        <table className={styles.table}>
          <thead>
            <tr className={styles.th}>
              <th className={styles.thText}>Script</th>
              <th className={styles.thText}>Level</th>
              <th className={styles.thText}>Requester</th>
              <th className={styles.thText}>Status</th>
              <th className={styles.thText}>Needed By</th>
            </tr>
          </thead>
          {list.length > 0 ? (
            <tbody>
              {list.map(request => {
                return (
                  <tr className={styles.tr}>
                    <td className={styles.td}>
                      <Link className={styles.link} to={{ search: `?view=coverageDetails&request=${request.id}` }}>
                        {request.script && request.script.title}
                      </Link>
                    </td>
                    <td className={styles.td}>{request.level}</td>
                    <td className={styles.td}>{request.desk}</td>

                    <td className={styles.td} style={{}}>
                      {request.completed ||
                        (request.started
                          ? 'Started'
                          : request.assigned
                          ? 'Assigned'
                          : request.submitted
                          ? 'Submitted'
                          : '')}
                    </td>
                    <td className={styles.td} style={{ textAlign: 'right' }}>
                      {request.needed}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody />
          )}
        </table>
      </div>
    );
  }
}

const withStyles = styled({
  card: {
    minWidth: 275,
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    color: colors.contentColor,
    fontSize: 12,
    textTransform: 'uppercase',
  },
  thText: {
    textAlign: 'left',
    paddingLeft: 10,
    paddingTop: 10,
  },
  link: { color: '#4A4A4A', textDecoration: 'underline' },
  td: {
    fontSize: 11,
    fontWeight: 300,
    color: colors.text,
    paddingTop: 1,
    paddingBottom: 1,
    paddingLeft: '10px',
    backgroundColor: '#FFF',
    border: '1px solid #F5F5F5',
    borderBottom: '0px',
    borderTopColor: '#EBEBEB',
    '&:last-child': {
      borderBottom: `1px solid ${colors.border}`,
    },
    backgroundColor: colors.contentBackground,
    border: `1px solid ${colors.background}`,
    borderTopColor: colors.border,
    borderBottom: 0,
  },
  tdFocus: {
    backgroundColor: colors.focusBackground,
  },
  tdActive: styles => ({
    border: `1px solid ${colors.focusBorder}`,
    [`& + .${styles.td}`]: {
      borderTop: 0,
    },
  }),
  tr: {
    '&:hover': {
      '>td': {
        backgroundColor: '#F9F9F9',
      },
    },
  },
  scriptButton: helpers.merge(elements.button, elements.actionable, {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderColor: '#000',
    paddingTop: '0px',
    paddingBottom: '0px',
    height: '30px',
  }),
  scriptButtonIcon: helpers.merge(
    {
      marginRight: 10,
    },
    icons.plus
  ),
  buttonText: {
    width: '60px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const withState = connect(state => {
  const { scripts } = state;
  return { scripts };
});

export default withRouter(withState(withStyles(CoverageAssignments)));
