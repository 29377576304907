const createPermission = function(feature, display, scopes, parent) {
  return { application: 'scripts', feature, display, scopes, parent };
};
const permissionList = [];
permissionList.push(createPermission('coverageRequests', 'Coverage Requests'));
permissionList.push(
  createPermission(
    'coverageRequestDetails',
    'Coverage Request Details',
    ['Submit', 'Assign', 'Finalize', 'Archive'],
    'coverageRequests'
  )
);
permissionList.push(createPermission('scriptList', 'Script List'));
permissionList.push(
  createPermission('scriptDetails', 'Script Details', ['Read', 'Create', 'Update', 'Delete'], 'scriptList')
);
permissionList.push(createPermission('coverage', 'Coverage'));
permissionList.push(createPermission('reports', 'Reports'));
permissionList.push(createPermission('accessManagement', 'Access Management'));
permissionList.push(createPermission('accessRequests', 'Script Access Requests'));
permissionList.push(createPermission('changeRequests', 'Script Change Requests'));
permissionList.findByFeature = function(feature) {
  return permissionList.find(permission => {
    return permission.feature === feature;
  });
};
export default permissionList;
