import * as React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { styled, helpers } from 'react-free-style';
//import { Row, Card, CardText, CardBody, CardTitle, CardSubtitle, Button, Table } from 'reactstrap';
import * as colors from '../../../styles/colors';
import * as elements from '../../../styles/elements';
import * as icons from '../../../styles/icons';
import { Spinner, BasicCard, BasicInfo, Info, Input } from 'julius-frontend-components';
import { hasAccess } from '../../../support/auth';
import { searchCompany } from 'julius-frontend-store';
import { Container, Row, Col, Button, Form, FormGroup, Label, ListGroup, ListGroupItem } from 'reactstrap';
import { Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import Character from '../../components/character';
class ReviewRoles extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      type: '',
    };
  }

  componentWillMount() {
    this.loadDetails();
  }

  async loadDetails() {
    const { dispatch } = this.props;
    //dispatch(something);
  }

  render() {
    const { styles, assignment, onChange } = this.props;
    const { roles } = assignment;
    return (
      <Container className={styles.container}>
        <Row className={styles.row}>
          <Col className={styles.col}>
            <button
              id="addCharacterButton"
              className={styles.scriptButton}
              onClick={() => {
                assignment.roles.push({ character: 'New Character' });
                this.forceUpdate();
              }}
            >
              <span className={styles.buttonText}>
                <i className={styles.scriptButtonIcon} /> Add Role{' '}
              </span>
            </button>
          </Col>
        </Row>
        <Row className={styles.row}>
          <Col className={styles.col}>
            {roles
              .filter((r, i) => {
                return i % 2 === 0;
              })
              .map(r => {
                return (
                  <Character
                    role={r}
                    styles={styles}
                    editMode={true}
                    onChange={() => {
                      this.forceUpdate();
                    }}
                  />
                );
              })}
          </Col>
          <Col className={styles.col}>
            {roles
              .filter((r, i) => {
                return i % 2 === 1;
              })
              .map(r => {
                return (
                  <Character
                    role={r}
                    styles={styles}
                    editMode={true}
                    onChange={() => {
                      this.forceUpdate();
                    }}
                  />
                );
              })}
          </Col>
        </Row>
      </Container>
    );
  }
}

const withStyles = styled({
  container: { margin: '0px', width: '100%' },
  row: { margin: '0px' },
  col: { margin: '0px' },
  card: {
    minWidth: 275,
    marginBottom: '10px',
  },
  cardTitle: {
    fontFamily: 'Gotham, sans-serif',
    fontSize: '12px',
    textTransform: 'uppercase',
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    color: colors.contentColor,
    fontSize: 12,
    textTransform: 'uppercase',
  },
  thText: {
    textAlign: 'left',
    padding: 10,
  },
  scriptButton: helpers.merge(elements.button, elements.actionable, {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderColor: '#000',
    paddingTop: '0px',
    paddingBottom: '0px',
    marginBottom: '10px',
    height: '30px',
  }),
  scriptButtonIcon: helpers.merge(
    {
      marginRight: 10,
    },
    icons.plus
  ),
  buttonText: {
    width: '60px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const withState = connect(state => {
  return {};
});

export default withRouter(withState(withStyles(ReviewRoles)));
