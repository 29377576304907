import * as React from 'react';
import axios from 'axios';

import { connect } from 'react-redux';
import { notify } from 'react-notify-toast';
import { parse } from 'querystring';
import { styled, helpers } from 'react-free-style';
import { withRouter, Link, Redirect } from 'react-router-dom';

import * as elements from '../../../styles/elements';
import * as icons from '../../../styles/icons';
import * as sizes from '../../../styles/sizes';
import AssignmentView from './assignment';
import AuthView from '../../auth-view';
import CoverageView from './coverage';
import GrantsView from './grants';
import HistoryView from './history';
import RolesView from './roles';
import SubmissionView from './submission';
import { hasAccess } from '../../../support/auth';
import { apiServerUrl } from '../../../support/urls';

import {
  coverageRequestDetails,
  coverageRequestSave,
  scriptSave,
  scriptVersionSave,
  scriptVersionList,
  assignmentAdd,
  assignmentRemove,
  assignmentList,
  scriptAttachmentList,
  scriptAttachmentAdd,
  scriptAttachmentRemove,
  scriptAttachmentUpdate,
} from 'julius-frontend-store';

class CoverageRequests extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      type: '',
      request: {},
      tempRequest: {},
    };
  }
  tabs = [
    {
      id: 'submission',
      title: 'Submission',
      view: (
        <SubmissionView
          onSaveSubmissionInfo={this.saveSubmissionInfo.bind(this)}
          onCancelSubmissionInfo={this.cancelSubmissionInfo.bind(this)}
          onSaveScript={this.saveScriptSelect.bind(this)}
          onCancelScript={this.cancelScriptSelect.bind(this)}
          onSaveScriptVersion={this.saveScriptVersion.bind(this)}
          onCancelScriptVersion={this.cancelScriptVersion.bind(this)}
          onSubmitRequest={this.submitRequest.bind(this)}
          onAssignRequest={this.assignRequest.bind(this)}
          onCompleteRequest={this.completeRequest.bind(this)}
          onSaveScriptAttachments={this.saveScriptAttachments.bind(this)}
          onCancelScriptAttachments={this.cancelScriptAttachments.bind(this)}
        />
      ),
      isVisible: () => {
        return true;
      },
    },
    {
      id: 'assignment',
      title: 'Assignment',
      view: (
        <AssignmentView
          onAssignmentsSave={this.assignmentsSave.bind(this)}
          onAssignmentsCancel={this.assignmentsCancel.bind(this)}
          onCoverageReview={assignment => {
            const r = Object.assign({}, this.state.request);
            console.log('coverage passed in');
            console.log(assignment);
            r.coverage = JSON.parse(JSON.stringify(assignment.coverage));
            r.coverage.pending = true;
            this.setState({ request: r });
            //this.props.history.push(`/?view=coverageRequestDetails&request=${r.id}&tab=coverage`);
            //this.props.dispatch(coverageRequestSave(r)).then(result => {
            //  this.setState({ request: result.body });
            //  this.props.history.push(`/?view=coverageRequestDetails&request=${result.body.id}&tab=coverage`);
            //});
          }}
        />
      ),
      isVisible: request => {
        return request.submitted != null && hasAccess('coverageRequestDetails', 'Assign');
      },
    },
    {
      id: 'coverage',
      title: 'Coverage',
      view: <CoverageView />,
      isVisible: request => {
        return (
          request.completed != null || (hasAccess('coverageRequestDetails', 'Finalize') && request.coverage != null)
        );
      },
    },
    {
      id: 'roles',
      title: 'Roles',
      view: <RolesView />,
      isVisible: request => {
        return (
          request.completed != null || (hasAccess('coverageRequestDetails', 'Finalize') && request.coverage != null)
        );
      },
    },
    {
      id: 'grants',
      title: 'Grants',
      view: <GrantsView />,
      isVisible: () => {
        return false;
      },
    },
    {
      id: 'history',
      title: 'History',
      view: <HistoryView />,
      isVisible: () => {
        return false;
      },
    },
  ];
  componentWillMount() {
    this.loadDetails();
  }

  async loadDetails() {
    const { dispatch } = this.props;
    const qs = parse(this.props.location.search.substr(1));
    const request = qs.request;

    dispatch(coverageRequestDetails(request))
      .then(result => {
        //const versionsPromise = result.script == null ? Promise.resolve(result) :
        const coverageRequest = result.body
          ? JSON.parse(JSON.stringify(result.body))
          : {
              level: 'Standard',
              desk: this.props.desk ? this.props.desk : this.props.desks && this.props.desks[0],
            };
        return coverageRequest;
      })
      .then(coverageRequest => {
        //load script versions
        if (coverageRequest.script == null) {
          return coverageRequest;
        } else {
          return dispatch(scriptVersionList(coverageRequest.script))
            .then(versionResults => {
              coverageRequest.script.versions = JSON.parse(JSON.stringify(versionResults.body));
              return coverageRequest;
            })
            .then(coverageRequest => {
              return dispatch(scriptAttachmentList(coverageRequest.script)).then(attachmentResults => {
                coverageRequest.script.attachments = JSON.parse(JSON.stringify(attachmentResults.body));
                return coverageRequest;
              });
            });
        }
      })
      .then(coverageRequest => {
        if (coverageRequest._id == null) {
          return coverageRequest;
        }
        //load assignments
        return dispatch(assignmentList(coverageRequest)).then(assignmentResults => {
          coverageRequest.assignments = assignmentResults.body;
          return coverageRequest;
        });
      })
      .then(coverageRequest => {
        this.setState({
          request: coverageRequest,
          tempRequest: JSON.parse(JSON.stringify(coverageRequest)),
        });
      });
  }

  getActiveTab() {
    const { tab } = this.state;
    const qs = parse(this.props.location.search.substr(1));
    const _tab = this.tabs.find(x => {
      return (qs.tab || 'submission') === x.id;
    });
    return _tab;
  }
  getVisibleTabs(request) {
    return this.tabs.filter(tab => {
      return tab.isVisible(request);
    });
  }
  render() {
    const { styles, scopes, scripts, desks, desk, user, dispatch } = this.props;
    const { request, tempRequest, scriptVersions, tempVersion } = this.state;

    const activeTab = this.getActiveTab();
    const visibleTabs = this.getVisibleTabs(request);
    return (
      <div className={styles.container}>
        <div className={styles.header} />
        {visibleTabs.length > 1 && (
          <div className={styles.content}>
            <div className={styles.menu}>{this.renderTabs(visibleTabs, styles, activeTab, request)}</div>
          </div>
        )}
        <div className={styles.body}>
          <div className={styles.content}>
            <AuthView
              view={activeTab.view}
              feature="coverageRequestDetails"
              request={request}
              tempRequest={tempRequest}
              onChange={() => {
                this.setState({});
              }}
              desks={desks}
              desk={desk}
              user={user}
            />
          </div>
        </div>
      </div>
    );
  }
  saveCoverage(request) {
    const { dispatch } = this.props;
    const isNew = request._id == null;
    return dispatch(coverageRequestSave(request))
      .then(result => {
        notify.show('Coverage Request Saved', 'success', 5000);
        if (isNew) {
          this.props.history.push(`/?view=coverageRequestDetails&request=${result.body._id}`);
        }
        this.loadDetails();
      })
      .catch(error => {
        notify.show('Error Saving Coverage Request', 'error', 5000);
      });
  }
  submitRequest() {
    const { request } = this.state;
    request.submitted = new Date();
    this.saveCoverage(request);
  }
  assignRequest() {
    const { request } = this.state;
    request.assigned = new Date();
    this.saveCoverage(request);
  }
  completeRequest() {
    const { request } = this.state;
    if (request.completed == null) {
      request.completed = new Date();
    }
    this.saveCoverage(request);
  }
  saveScript(script) {
    const { dispatch } = this.props;
    return dispatch(scriptSave(script))
      .then(result => {
        notify.show('Script Saved', 'success', 5000);
        return result.body;
      })
      .then(result => {
        return dispatch(scriptVersionList(result))
          .then(versionResults => {
            this.setState({ scriptVersions: versionResults.body });
          })
          .then(() => {
            return result;
          });
      })
      .catch(error => {
        notify.show('Error Saving Script', 'error', 5000);
      });
  }

  submissionInfoFields = [
    'desk',
    'needed',
    'level',
    'client',
    'representation',
    'openDirecting',
    'openWriting',
    'openCasting',
    'comments',
  ];
  cancelSubmissionInfo() {
    const tempRequest = Object.assign({}, this.state.tempRequest);
    const request = this.state.request;
    this.submissionInfoFields.forEach(field => {
      tempRequest[field] = request[field];
    });
    this.setState({ tempRequest });
  }
  saveSubmissionInfo() {
    const tempRequest = this.state.tempRequest;
    const request = Object.assign({}, this.state.request);
    this.submissionInfoFields.forEach(field => {
      request[field] = tempRequest[field];
    });
    return this.saveCoverage(request);
  }
  cancelScriptSelect() {
    const tempRequest = Object.assign({}, this.state.tempRequest);
    const request = this.state.request;
    tempRequest.script = request.script == null ? null : JSON.parse(JSON.stringify(request.script));
    this.setState({ tempRequest });
  }
  saveScriptSelect() {
    const tempRequest = this.state.tempRequest;
    const request = Object.assign({}, this.state.request);
    if (tempRequest.script != null) {
      return this.saveScript(tempRequest.script).then(script => {
        request.script = script;
        return this.saveCoverage(request);
      });
    } else {
      request.script = null;
      return this.saveCoverage(request);
    }
  }
  cancelScriptVersion() {
    const tempRequest = Object.assign({}, this.state.tempVersion);
    const request = this.state.request;
    tempRequest.scriptVersion = JSON.parse(JSON.stringify(request.scriptVersion));
    this.setState({ tempRequest });
  }

  async saveScriptVersion() {
    const { dispatch, user } = this.props;
    const tempRequest = this.state.tempRequest;
    const request = Object.assign({}, this.state.request);
    const isNew = tempRequest.scriptVersion._id == null;

    let scriptVersion = null;
    let parsedVersion = null;

    if (tempRequest.scriptVersion.selectedFile) {
      // TODO: push to the store
      const fileName = tempRequest.scriptVersion.selectedFile.name;

      const formData = new FormData();
      formData.append('file', tempRequest.scriptVersion.selectedFile, fileName);
      formData.append('version', tempRequest.scriptVersion.version);
      formData.append('pages', tempRequest.scriptVersion.pages);

      const jwtToken = user.auth;
      const headers = {
        Authorization: 'JWT ' + jwtToken,
      };

      scriptVersion = await axios.post(
        `${apiServerUrl}/script-coverage/scripts/${request.script._id}/versions`,
        formData,
        {
          headers,
        }
      );

      notify.show(`File '${fileName}' Uploaded to Box`, 'success', 5000);
      parsedVersion = JSON.parse(JSON.stringify(scriptVersion.data));
    } else {
      scriptVersion = await dispatch(scriptVersionSave(request.script, tempRequest.scriptVersion));
      parsedVersion = JSON.parse(JSON.stringify(scriptVersion.body));
    }

    if (isNew) {
      request.script.versions.push(parsedVersion);
    } else {
      const versionIndex = request.script.versions.findIndex(v => {
        return v._id === scriptVersion.body._id;
      });
      request.script.versions[versionIndex] = parsedVersion;
    }
    request.scriptVersion = parsedVersion;
    tempRequest.scriptVersion = parsedVersion;
    this.setState({ request, tempRequest });
    notify.show('Script Version Saved', 'success', 5000);
    this.saveCoverage(request);
  }

  assignmentsSave() {
    const { dispatch } = this.props;
    const tempRequest = Object.assign({}, this.state.tempRequest);
    const request = Object.assign({}, this.state.request);
    const assignmentAdds = [];
    const assignmentRemoves = [];
    if (tempRequest.assignments != null) {
      tempRequest.assignments.forEach(assignment => {
        if (assignment._id == null) {
          assignmentAdds.push(dispatch(assignmentAdd(request, assignment)));
        }
      });
    }
    if (request.assignments != null) {
      request.assignments.forEach(assignment => {
        if (
          !tempRequest.assignments.some(ta => {
            return ta._id === assignment._id;
          })
        ) {
          assignmentRemoves.push(dispatch(assignmentRemove(request, assignment)));
        }
      });
    }
    return Promise.all(assignmentRemoves)
      .then(results => {
        //remove from request
        request.assignments = request.assignments.filter(assignment => {
          return !results.some(result => {
            return result.body._id === assignment._id;
          });
        });
      })
      .then(() => {
        Promise.all(assignmentAdds).then(results => {
          //add to request
          tempRequest.assignments = tempRequest.assignments.filter(assignment => {
            return assignment._id != null;
          });
          results.forEach(result => {
            request.assignments.push(JSON.parse(JSON.stringify(result.body)));
            tempRequest.assignments.push(JSON.parse(JSON.stringify(result.body)));
          });
          this.setState({ request, tempRequest });
          notify.show('Coverage Readers Updated', 'success', 5000);
        });
      });
  }
  assignmentsCancel() {
    const tempRequest = Object.assign({}, this.state.tempRequest);
    const request = this.state.request;
    tempRequest.assignments = JSON.parse(JSON.stringify(request.assignments));
    this.setState({ tempRequest });
  }
  saveScriptAttachments() {
    const { dispatch } = this.props;
    const tempRequest = Object.assign({}, this.state.tempRequest);
    const request = Object.assign({}, this.state.request);
    const attachmentAdds = [];
    const attachmentRemoves = [];
    const attachmentUpdates = [];
    if (tempRequest.script.attachments != null) {
      tempRequest.script.attachments
        .filter(attachment => {
          return attachment._id != null && attachment.isDirty;
        })
        .forEach(attachment => {
          attachmentUpdates.push(dispatch(scriptAttachmentUpdate(request.script, attachment)));
        });
    }
    if (tempRequest.script.attachments != null) {
      tempRequest.script.attachments.forEach(attachment => {
        if (attachment._id == null) {
          attachmentAdds.push(dispatch(scriptAttachmentAdd(request.script, attachment)));
        }
      });
    }
    if (request.script.attachments != null) {
      request.script.attachments.forEach(attachment => {
        if (
          !tempRequest.script.attachments.some(ta => {
            return ta._id === attachment._id;
          })
        ) {
          attachmentRemoves.push(dispatch(scriptAttachmentRemove(request.script, attachment)));
        }
      });
    }
    return Promise.all(attachmentRemoves)
      .then(results => {
        //remove from request
        request.script.attachments = request.script.attachments.filter(attachment => {
          return !results.some(result => {
            return result.body._id === attachment._id;
          });
        });
      })
      .then(() => {
        return Promise.all(attachmentAdds).then(results => {
          //add to request
          tempRequest.script.attachments = tempRequest.script.attachments.filter(attachment => {
            return attachment._id != null;
          });
          results.forEach(result => {
            request.script.attachments.push(JSON.parse(JSON.stringify(result.body)));
            tempRequest.script.attachments.push(JSON.parse(JSON.stringify(result.body)));
          });
        });
      })
      .then(results => {
        //update existing
        return Promise.all(attachmentUpdates)
          .then(results => {
            results.forEach(result => {
              const index = request.script.attachments.findIndex(attachment => {
                return attachment._id === result.body._id;
              });
              request.script.attachments[index] = JSON.parse(JSON.stringify(result.body));
              tempRequest.script.attachments[index] = JSON.parse(JSON.stringify(result.body));
            });
          })
          .then(() => {
            this.setState({ request, tempRequest });
            notify.show('Script Attachments Updated', 'success', 5000);
          });
      });
  }
  cancelScriptAttachments() {
    const tempRequest = Object.assign({}, this.state.tempRequest);
    const request = this.state.request;
    tempRequest.script.attachments = JSON.parse(JSON.stringify(request.script.attachments));
    this.setState({ tempRequest });
  }

  renderTabs(visibleTabs, styles, activeTab, request) {
    return visibleTabs.map(tab => (
      <Link
        key={tab.title}
        to={{ search: `?view=coverageRequestDetails&request=${request._id}&tab=${encodeURIComponent(tab.id)}` }}
        className={`${styles.menuItem} ${activeTab.id === tab.id ? styles.activeItem : ''}`}
      >
        {tab.title}
      </Link>
    ));
  }
}

const withStyles = styled({
  content: helpers.merge(sizes.container, {
    display: 'flex',
    flexDirection: 'column',
  }),
  container: { margin: '0px', width: '100%' },
  row: { margin: '0px' },
  col: { margin: '0px', paddingRight: '20px' },
  button: { padding: '20px' },
  menu: helpers.merge(elements.menu, {
    marginBottom: 20,
  }),
  menuItem: elements.menuItem,
  activeItem: elements.activeMenuItem,
  editIcon: icons.pencil,
});

const withState = connect(store => {
  const { scripts, user } = store;
  const desks = store.user.user.deskIds;
  const desk = store.user.desk || (desks != null && desks[0]);

  return { scripts, desks, desk, user };
});

export default withRouter(withState(withStyles(CoverageRequests)));
