import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { styled } from 'react-free-style';
//import { Row, Card, CardText, CardBody, CardTitle, CardSubtitle, Button, Table } from 'reactstrap';
import { Container, Row, Col, Button, Form, FormGroup, Label, Input, ListGroup, ListGroupItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import permissions from '../../support/permissions';
import { Spinner, AddButton, Info } from 'julius-frontend-components';
import { roleDetails, roleUpdate, roleCreate } from 'julius-frontend-store';
import * as qs from 'query-string';

class RoleDetailsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      role: { name: '', groups: [], permissions: [] },
      name: '',
      type: '',
      adGroup: '',
    };
  }

  componentWillMount() {
    this.loadDetail();
  }

  loadDetail() {
    const { dispatch } = this.props;
    const roleId = qs.parse(this.props.location.search).role;
    const roleDetailFunc = roleDetails(roleId);
    dispatch(roleDetailFunc).then(result => {
      this.setState({ role: result.body });
    });
  }

  render() {
    const { styles, scripts, adGroups } = this.props;
    //const role = scripts.role;
    const { adGroup, role } = this.state;

    return (
      <Container>
        <Row>
          <Col>
            <Form>
              <FormGroup row>
                <Label for="roleName" sm={2}>
                  Role Name
                </Label>
                <Col sm={10}>
                  <Input
                    type="text"
                    name="text"
                    id="roleName"
                    placeholder="Role Name"
                    value={role.name}
                    onChange={e => {
                      this.setRoleName(e.target.value);
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="adGroups" sm={2}>
                  AD Groups
                </Label>
                <Col>
                  <Container>
                    <Row>
                      <Col style={{ padding: '0px' }}>
                        <Input
                          type="text"
                          name="groupSearch"
                          id="groupSearch"
                          placeholder="AD Group Object ID..."
                          onChange={e => {
                            this.setADGroup(e.target.value);
                          }}
                          value={adGroup}
                        />
                      </Col>
                      <Col style={{ padding: '0px' }}>
                        <Button
                          onClick={() => {
                            this.addADGroup();
                          }}
                          disabled={!this.adGroupIsValid(adGroup)}
                        >
                          Add
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ padding: '0px' }}>
                        <ListGroup style={{ overflow: 'auto', overflowX: 'hidden', maxHeight: '200px' }}>
                          {role.groups.map(group => {
                            return (
                              <ListGroupItem className="adGroupItem">
                                <span style={{ float: 'right' }}>
                                  <a
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      this.removeGroup(group.objectId);
                                    }}
                                  >
                                    Remove
                                  </a>
                                </span>
                                <span>
                                  <div style={{ fontSize: '1em' }}>{group.displayName}</div>
                                  <div style={{ fontSize: '0.8em', fontFamily: 'Courier New' }}>{group.objectId}</div>
                                </span>
                              </ListGroupItem>
                            );
                          })}
                        </ListGroup>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="features" sm={2}>
                  Features
                </Label>
                <Col>
                  <Container style={{ paddingLeft: '20px', paddingTop: '7px' }}>
                    {permissions
                      .filter(permission => {
                        return permission.parent == null;
                      })
                      .map(permission => {
                        return (
                          <Row>
                            <Col>{this.renderPermission(permission, role)}</Col>
                            {permissions
                              .filter(child => {
                                return child.parent === permission.feature;
                              })
                              .map(child => {
                                return <Col>{this.renderPermission(child, role)}</Col>;
                              })}
                          </Row>
                        );
                      })}
                  </Container>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={{ size: 10, offset: 2 }}>
                  <Button onClick={() => this.save()} disabled={role.name == null || role.name.trim() === ''}>
                    Save
                  </Button>
                </Col>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
  setADGroup(text) {
    this.setState({ adGroup: text });
  }
  removeGroup(objectId) {
    const { role } = this.state;
    role.groups = role.groups.filter(g => {
      return g.objectId !== objectId;
    });
    this.setState({ role });
  }
  adGroupIsValid(group) {
    const regex = /^([a-z0-9]){8}-([a-z0-9]){4}-([a-z0-9]){4}-([a-z0-9]){4}-([a-z0-9]){12}$/g;
    return regex.test(group);
  }
  renderPermission(permission, role) {
    return (
      <div>
        <Input
          type="checkbox"
          id={permission.feature}
          checked={this.roleContainsPermission(role, permission)}
          onClick={e => {
            this.toggleFeature(permission, role);
          }}
        />
        <span style={permission.scopes != null ? { textDecoration: 'underline' } : {}}>{permission.display}</span>
        {permission.scopes != null && (
          <ul style={{ marginBottom: '0px' }}>
            {permission.scopes.map(scope => {
              return (
                <div>
                  <Input
                    type="checkbox"
                    id={`${permission.feature}:${scope}`}
                    disabled={!this.roleContainsPermission(role, permission)}
                    checked={this.permissionContainsScope(role, permission, scope)}
                    onClick={() => {
                      this.toggleScope(role, permission, scope);
                    }}
                  />{' '}
                  {scope}
                </div>
              );
            })}
          </ul>
        )}
      </div>
    );
  }
  setRoleName(name) {
    const { role } = this.state;
    role.name = name;
    this.setState({ role });
  }
  roleContainsPermission(role, permission) {
    return (
      role.permissions != null &&
      role.permissions.some(p => {
        return p.feature === permission.feature;
      })
    );
  }
  permissionContainsScope(role, permission, scope) {
    if (role.permissions == null) {
      return false;
    }
    const rolePermission = role.permissions.find(p => {
      return p.feature === permission.feature;
    });
    return (
      rolePermission != null &&
      rolePermission.scopes != null &&
      rolePermission.scopes.some(s => {
        return s === scope;
      })
    );
  }
  toggleFeature(permission, role) {
    if (role.permissions == null) {
      role.permissions = [];
    }

    if (this.roleContainsPermission(role, permission) === false) {
      const rolePerm = { feature: permission.feature };
      role.permissions.push(rolePerm);
    } else {
      role.permissions = role.permissions.filter(p => {
        return p.feature !== permission.feature;
      });
    }
    this.setState({ role });
  }
  toggleScope(role, permission, scope) {
    const rolePermission = role.permissions.find(p => {
      return p.feature === permission.feature;
    });
    if (rolePermission.scopes == null) {
      rolePermission.scopes = [];
    }
    if (
      rolePermission.scopes.some(s => {
        return s === scope;
      }) == true
    ) {
      rolePermission.scopes = rolePermission.scopes.filter(s => {
        return s !== scope;
      });
    } else {
      rolePermission.scopes.push(scope);
    }
    this.setState({ role });
  }
  changeRoleName(name) {
    const { dispatch } = this.props;
    dispatch();
  }
  addADGroup() {
    const { role, adGroup } = this.state;
    if (role.groups == null) {
      role.groups = [];
    }
    role.groups.push({ displayName: 'Pending Save...', objectId: adGroup });

    this.setState({ role, adGroup: '' });
  }
  save() {
    const { role } = this.state;
    if (role.id === 'new') {
      this.props.dispatch(roleCreate(role)).then(() => {
        this.props.history.push('/?view=accessManagement');
      });
    } else {
      this.props.dispatch(roleUpdate(role)).then(() => {
        this.props.history.push('/?view=accessManagement');
      });
    }
  }
}

const withStyles = styled({
  thText: {
    textAlign: 'left',
    padding: 10,
  },
  adGroupItem: {
    paddingTop: '4px',
    paddingRight: '4px',
    paddingBottom: '4px',
  },
  adGroupName: {
    size: '12px',
  },
  adObjectId: {
    size: '8px',
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const withState = connect(state => {
  const { scripts } = state;
  return { scripts };
});

export default withRouter(withState(withStyles(RoleDetailsView)));
