import * as React from 'react';

import { styled } from 'react-free-style';
import { Info, Select, TextArea } from 'julius-frontend-components';
import ButtonSelector from './button-selector';
import { Card, CardBody, CardTitle } from 'reactstrap';

class CoverageRecommendations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      type: '',
    };
  }
  getRatingSelector(element, item) {
    const ratings = [1, 2, 3, 4, 5];
    return <ButtonSelector values={ratings} targetObject={element} targetField={item} />;
  }
  getRecommendationSelector(title, element, item, onChange) {
    const recommendations = ['Pass', 'Weak Consider', 'Consider', 'Strong Consider', 'Recommend', 'Strong Recommend'];
    return this.getSelector(title, recommendations, element, item, onChange);
  }
  getSelector(title, values, element, item, onChange) {
    return (
      <Select
        title={title || item}
        children={element[item]}
        items={values.map(r => {
          return {
            key: r,
            content: r,
            active: false,
            onClick: () => {
              element[item] = r;
              onChange == null && this.setState({});
              onChange != null && onChange();
            },
          };
        })}
      />
    );
  }

  render() {
    const { styles, assignment, onChange } = this.props;
    return (
      <Card className={styles.card}>
        <CardBody>
          <CardTitle className={styles.cardTitle}>Recommendations</CardTitle>
          {this.getRecommendationSelector(
            'Script Recommendation',
            assignment.script,
            'score',
            onChange && onChange(assignment)
          )}
          <Info name="&nbsp;> Character">{this.getRatingSelector(assignment.script, 'character')}</Info>
          <Info name="&nbsp;> Tone">{this.getRatingSelector(assignment.script, 'tone')}</Info>
          <Info name="&nbsp;> Dialog">{this.getRatingSelector(assignment.script, 'dialog')}</Info>
          <Info name="&nbsp;> Visual Elements">{this.getRatingSelector(assignment.script, 'visual')}</Info>
          {this.getRecommendationSelector(
            'Story Recommendation',
            assignment.story,
            'score',
            onChange && onChange(assignment)
          )}
          <Info name="&nbsp;> Originality">{this.getRatingSelector(assignment.story, 'originality')}</Info>
          <Info name="&nbsp;> Structure">{this.getRatingSelector(assignment.story, 'structure')}</Info>
          <Info name="&nbsp;> Plotting">{this.getRatingSelector(assignment.story, 'plotting')}</Info>
          <Info name="&nbsp;> Commerciality">{this.getRatingSelector(assignment.story, 'commerciality')}</Info>
          {this.getRecommendationSelector(
            'Writer Recommendation',
            assignment.writer,
            'score',
            onChange && onChange(assignment)
          )}
          <TextArea
            title="Writer Comments"
            onChange={text => {
              assignment.writer.comments = text;
            }}
          >
            {assignment.writer.comments}
          </TextArea>
        </CardBody>
      </Card>
    );
  }
}
const withStyles = styled({
  card: {
    minWidth: 275,
    marginBottom: '10px',
  },
  cardTitle: {
    fontFamily: 'Gotham, sans-serif',
    fontSize: '12px',
    textTransform: 'uppercase',
  },
});
export default withStyles(CoverageRecommendations);
