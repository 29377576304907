import * as React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { styled, helpers } from 'react-free-style';
import * as colors from '../../../styles/colors';
import * as elements from '../../../styles/elements';
import * as icons from '../../../styles/icons';
import { Spinner } from 'julius-frontend-components';
import { hasAccess } from '../../../support/auth';
import * as sizes from '../../../styles/sizes';
import { parse } from 'querystring';
import SubmissionView from './submission';
import RolesView from './roles';
import CoverageView from './coverage';
import GrantsView from './grants';
import HistoryView from './history';
import { coverageRequestDetails } from 'julius-frontend-store';
import { Container, Row, Col, Button, Form, FormGroup, Label, ListGroup, ListGroupItem } from 'reactstrap';

const tabs = [
  { id: 'submission', title: 'Script', view: <SubmissionView /> },
  { id: 'coverage', title: 'Coverage', view: <CoverageView /> },
  { id: 'roles', title: 'Roles', view: <RolesView /> },
  { id: 'grants', title: 'Grants', view: <GrantsView /> },
  { id: 'history', title: 'History', view: <HistoryView /> },
];

class ScriptDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      type: '',
    };
  }

  componentWillMount() {
    this.loadDetails();
  }

  async loadDetails() {
    const { dispatch } = this.props;
    const qs = parse(this.props.location.search.substr(1));
    const request = qs.request;
    dispatch(coverageRequestDetails(request));
  }

  getActiveTab() {
    const { tab } = this.state;
    const qs = parse(this.props.location.search.substr(1));
    const _tab = tabs.find(x => {
      return (qs.tab || 'submission') === x.id;
    });
    return _tab;
  }
  render() {
    const { styles, scopes, scripts } = this.props;
    const crDetails = scripts.coverageRequestDetails;
    const activeTab = this.getActiveTab();
    return (
      <div className={styles.container}>
        <div className={styles.header} />
        <div className={styles.content}>
          <div className={styles.menu}>{this.renderTabs(styles, activeTab, crDetails)}</div>
        </div>
        <div className={styles.body}>
          <div className={styles.content}>{activeTab.view}</div>
        </div>
        <div className={styles.body}>
          <div className={styles.content}>
            <Container className={styles.container}>
              <Row className={styles.row}>
                <Col className={styles.col}>
                  <Button className="float-right" style={{ margin: '10px' }}>
                    Save
                  </Button>
                  <Button className="float-right" style={{ margin: '10px' }}>
                    Archive
                  </Button>
                  <br />
                  <br />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }
  renderTabs(styles, activeTab, request) {
    return tabs.map(tab => (
      <Link
        key={tab.title}
        to={{ search: `?view=scriptDetails&script=${request}&tab=${encodeURIComponent(tab.id)}` }}
        className={`${styles.menuItem} ${activeTab.id === tab.id ? styles.activeItem : ''}`}
      >
        {tab.title}
      </Link>
    ));
  }
}

const withStyles = styled({
  content: helpers.merge(sizes.container, {
    display: 'flex',
    flexDirection: 'column',
  }),
  container: { margin: '0px', width: '100%' },
  row: { margin: '0px' },
  col: { margin: '0px', paddingRight: '20px' },
  button: { padding: '20px' },
  menu: helpers.merge(elements.menu, {
    marginBottom: 20,
  }),
  menuItem: elements.menuItem,
  activeItem: elements.activeMenuItem,
  editIcon: icons.pencil,
});

const withState = connect(state => {
  const { scripts } = state;
  return { scripts };
});

export default withRouter(withState(withStyles(ScriptDetails)));
