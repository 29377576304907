import * as React from 'react';
import { connect } from 'react-redux';
import { helpers, styled } from 'react-free-style';
import { withRouter } from 'react-router-dom';

import { Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';
import { CompanyInput, Info, Select, TextArea } from 'julius-frontend-components';

import * as elements from '../../../styles/elements';
import * as icons from '../../../styles/icons';
import scriptFormItems from '../../../support/items/script-form-items';

class Submission extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      type: '',
    };
  }

  componentWillMount() {
    this.loadDetails();
  }

  async loadDetails() {
    const { dispatch, saveChanges, script } = this.props;
    //dispatch(something);
  }

  render() {
    const { styles, script, saveChanges, onChange } = this.props;
    const list = [];
    const editMode = false;
    return (
      <Container className={styles.container}>
        <Row className={styles.row}>
          <Col className={styles.col}>
            <Card className={styles.card}>
              <CardBody>
                <CardTitle className={styles.cardTitle}>Script Information</CardTitle>
                {editMode == true ? (
                  <div>
                    <Select
                      title="Submitting Office"
                      items={scriptFormItems.map(i => {
                        return { key: i, content: i, className: styles.selectItem, active: false, onClick: () => {} };
                      })}
                      className={styles.select}
                    />
                    <Select
                      title="Confidentiality"
                      items={['Public', 'Private', 'Invisible'].map(i => {
                        return { key: i, content: i, className: styles.selectItem, active: false, onClick: () => {} };
                      })}
                      className={styles.select}
                    />
                    <CompanyInput title="Studio" />
                    <CompanyInput title="Production Company" />
                    <Select
                      title="Client Material"
                      items={['Yes', 'No'].map(i => {
                        return { key: i, content: i, className: styles.selectItem, active: false, onClick: () => {} };
                      })}
                      className={styles.select}
                    />
                    <TextArea title="Comments" />
                  </div>
                ) : (
                  <div>
                    <Info name="Title">2001: A Space Odyssey</Info>
                    <Info name="Authors">Jay Dobyns & Nick Snowden</Info>
                    <Info name="Form">Screenplay</Info>
                    <Info name="Page Count">108</Info>
                    <Info name="Revision">3</Info>
                    <Info name="Studio">Universal Studios</Info>
                    <Info name="Production Company">Impossible Dream Entertainment</Info>
                    <Info name="Client Material">Yes</Info>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
          <Col className={styles.col}>
            <Card className={styles.card}>
              <CardBody>
                <CardTitle className={styles.cardTitle}>Version 1</CardTitle>
                {editMode == true ? (
                  <div>
                    <CompanyInput title="Client" />
                    <Select
                      title="Representation"
                      items={['Yes', 'No', 'Not Sure'].map(i => {
                        return { key: i, content: i, className: styles.selectItem, active: false, onClick: () => {} };
                      })}
                      className={styles.select}
                    />
                  </div>
                ) : (
                  <div>
                    <Info name="File">version1.pdf</Info>
                    <Info name="Created">1/1/1921</Info>
                  </div>
                )}
              </CardBody>
            </Card>
            <Card className={styles.card}>
              <CardBody>
                <CardTitle className={styles.cardTitle}>Version 2</CardTitle>
                {editMode == true ? (
                  <div>
                    <CompanyInput title="Client" />
                    <Select
                      title="Representation"
                      items={['Yes', 'No', 'Not Sure'].map(i => {
                        return { key: i, content: i, className: styles.selectItem, active: false, onClick: () => {} };
                      })}
                      className={styles.select}
                    />
                  </div>
                ) : (
                  <div>
                    <Info name="File">version1.pdf</Info>
                    <Info name="Created">12/11/1927</Info>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const withStyles = styled({
  container: { margin: '0px', width: '100%' },
  row: { margin: '0px' },
  col: { margin: '0px' },
  card: {
    minWidth: 275,
    marginBottom: '10px',
  },
  cardTitle: {
    fontFamily: 'Gotham, sans-serif',
    fontSize: '12px',
    textTransform: 'uppercase',
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  scriptButton: helpers.merge(elements.button, elements.actionable, {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderColor: '#000',
    paddingTop: '0px',
    paddingBottom: '0px',
    height: '30px',
  }),
  scriptButtonIcon: helpers.merge(
    {
      marginRight: 10,
    },
    icons.plus
  ),
  buttonText: {
    width: '60px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  select: {
    fontSize: '0.8em',
    fontWeight: 'bold',
    width: '170px',
    textOverflow: 'ellipsis',
  },
  formContainer: {
    display: 'flex',
    marginLeft: 5,
    marginTop: 10,
  },
  selectItem: {
    fontSize: 14,
    fontWeight: 300,
    padding: 10,
  },
});

const withState = connect(state => {
  return {};
});

export default withRouter(withState(withStyles(Submission)));
