import * as React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { styled, helpers } from 'react-free-style';
//import { Row, Card, CardText, CardBody, CardTitle, CardSubtitle, Button, Table } from 'reactstrap';
import * as colors from '../../../styles/colors';
import * as elements from '../../../styles/elements';
import * as icons from '../../../styles/icons';
import { Spinner, Info, Select, TextArea, Input } from 'julius-frontend-components';

import CoverageRecommendations from '../../components/coverage-recommendations';
import CoverageReport from '../../components/coverage-report';
import { hasAccess } from '../../../support/auth';
import { Container, Row, Col, Button, Form, FormGroup, Label, ListGroup, ListGroupItem } from 'reactstrap';
import { Card, CardImg, CardText, CardBody, CardTitle } from 'reactstrap';

class Coverage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      type: '',
    };
  }

  componentWillMount() {
    this.loadDetails();
  }

  async loadDetails() {}

  getSelector(title, values, element, item, onChange) {
    return (
      <Select
        title={title || item}
        children={element[item]}
        items={values.map(r => {
          return {
            key: r,
            content: r,
            active: false,
            onClick: () => {
              element[item] = r;
              onChange == null && this.setState({});
              onChange != null && onChange();
            },
          };
        })}
      />
    );
  }
  render() {
    const { styles, resources, assignment, onChange } = this.props;

    return (
      <Container className={styles.container}>
        <Row className={styles.row}>
          <Col className={styles.col}>
            <CoverageRecommendations assignment={assignment} onChange={onChange} />
          </Col>
          <Col className={styles.col}>
            <CoverageReport assignment={assignment} />
          </Col>
        </Row>
      </Container>
    );
  }
}

const withStyles = styled({
  container: { margin: '0px', width: '100%' },
  row: { margin: '0px' },
  col: { margin: '0px' },
  card: {
    minWidth: 275,
    marginBottom: '10px',
  },
  cardTitle: {
    fontFamily: 'Gotham, sans-serif',
    fontSize: '12px',
    textTransform: 'uppercase',
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    color: colors.contentColor,
    fontSize: 12,
    textTransform: 'uppercase',
  },
  thText: {
    textAlign: 'left',
    padding: 10,
  },
  scriptButton: helpers.merge(elements.button, elements.actionable, {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderColor: '#000',
    paddingTop: '0px',
    paddingBottom: '0px',
    height: '30px',
  }),
  scriptButtonIcon: helpers.merge(
    {
      marginRight: 10,
    },
    icons.plus
  ),
  buttonText: {
    width: '60px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const withState = connect(state => {
  return {};
});

export default withRouter(withState(withStyles(Coverage)));
