import * as React from 'react';
import { styled } from 'react-free-style';
import { connect } from 'react-redux';
import { parse } from 'querystring';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Branding, Search, Avatar } from 'julius-frontend-components';
import * as qs from 'query-string';
import CoverageRequestsView from './coverage-requests';
import CoverageRequestDetailsView from './coverage-requests/details';
import ScriptsView from './scripts';
import ScriptDetailsView from './scripts/details';
import AccessView from './access';
import ReportsView from './reports';
import RequestsView from './requests';
import ReviewsView from './reviews';
import ReviewDetailsView from './reviews/details';
import RoleDetailsView from './access/role-details';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import leftMenuBackground from '../assets/images/uta-background.jpg';
import '../styles/data-grid.css';
import * as colors from '../styles/colors';
import { hasAccess, getAuthToken } from '../support/auth';
import permissions from '../support/permissions';
import AuthView from './auth-view';

class Dashboard extends React.Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log('dashboard props');
    console.log(nextProps);
  }
  state = {
    view: 'scripts',
    search: '',
  };

  views = [
    {
      id: 'coverageRequests',
      container: (
        <AuthView
          view={<CoverageRequestsView />}
          feature="coverageRequests"
          user={this.props.user}
          desk={this.props.desk}
        />
      ),
      title: 'Coverage Requests',
      icon: 'fa-paper-plane-o',
      style: { color: '#000', margin: '10px' },
      supportsSearch: true,
      requiredPermission: 'coverageRequests',
    },
    {
      id: 'coverageRequestDetails',
      container: (
        <AuthView
          view={<CoverageRequestDetailsView />}
          feature="coverageRequestDetails"
          user={this.props.user}
          desk={this.props.desk}
        />
      ),
      parentView: 'coverageRequests',
      lookup: 'request',
      requiredPermission: 'coverageRequestDetails',
      back: 'coverageRequests',
    },
    {
      id: 'scriptList',
      container: <AuthView view={<ScriptsView />} feature="scriptList" />,
      title: 'Scripts',
      icon: 'fa-film',
      style: { color: '#000', margin: '10px' },
      supportsSearch: true,
      requiredPermission: 'scriptList',
      hidden: true,
    },
    {
      id: 'scriptDetails',
      parentView: 'scriptList',
      container: <AuthView view={<ScriptDetailsView />} feature="scriptDetails" />,
      lookup: 'script',
      requiredPermission: 'coverage',
    },
    {
      id: 'coverage',
      title: 'Coverage Assignments',
      icon: 'fa-thumbs-o-up',
      container: <AuthView view={<ReviewsView />} feature="coverage" />,
      style: { color: '#000', margin: '10px' },
      requiredPermission: 'scriptDetails',
    },
    {
      id: 'coverageDetails',
      parentView: 'coverage',
      container: <AuthView view={<ReviewDetailsView />} feature="coverage" />,
      lookup: 'request',
      requiredPermission: 'coverage',
    },
    {
      id: 'accessManagement',
      container: <AuthView view={<AccessView />} feature="accessManagement" />,
      title: 'Access',
      fullText: 'Access Management',
      icon: 'fa-universal-access',
      style: { color: '#000', margin: '10px' },
      requiredPermission: 'accessManagement',
      hidden: true,
    },
    {
      id: 'roleDetails',
      container: <AuthView view={<RoleDetailsView />} feature="accessManagement" />,
      parentView: 'accessManagement',
      lookup: 'role',
      requiredPermission: 'accessManagement',
    },
    {
      id: 'requests',
      title: 'Script Access Requests',
      icon: 'fa-magic',
      container: <AuthView view={<RequestsView />} feature="accessRequests" />,
      style: { color: '#000', margin: '10px' },
      requiredPermission: 'accessRequests',
    },
    {
      id: 'reports',
      title: 'Reports',
      icon: 'fa-clipboard',
      container: <AuthView view={<ReportsView />} feature="reports" />,
      style: { color: '#000', margin: '10px' },
      requiredPermission: 'reports',
      hidden: true,
    },
    {
      id: 'profile',
      title: 'Menu',
      icon: 'userImage',
    },
  ];

  componentWillMount() {
    this.setState(this.viewState(this.props));

    //apply profile image
    const profileView = this.views.find(view => {
      return view.id === 'profile';
    });
    profileView.style = {
      backgroundImage: `url(${
        this.props.user && this.props.user.personId && this.props.user.personId.profile_pic
          ? this.props.user.personId.profile_pic
          : Avatar
      })`,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this.viewState(nextProps));
  }

  getDefaultView() {
    return this.views.find(v => {
      return v.parentView == null && hasAccess(v.requiredPermission);
    });
  }
  viewState(props) {
    const qs = parse(props.location.search.substr(1));
    const viewObj = this.views.find(v => {
      return v.id === qs.view;
    });
    const view = viewObj != null && hasAccess(viewObj.requiredPermission) ? qs.view : this.getDefaultView().id;

    return { view };
  }

  getActiveView() {
    const { view } = this.state;
    const _view = this.views.find(x => {
      return view === x.id;
    });
    return _view;
  }
  getViewChain() {
    const viewChain = [];
    var _view = this.getActiveView();
    do {
      if (_view.fullText == null) {
        _view.fullText = _view.lookup == null ? _view.title : qs.parse(this.props.location.search)[_view.lookup];
      }
      viewChain.unshift(_view);
      _view = this.views.find(x => {
        return _view.parentView === x.id;
      });
    } while (_view != null);
    return viewChain;
  }
  getTopView() {
    var topView = this.getActiveView();
    while (topView.parentView != null) {
      topView = this.views.find(x => {
        return topView.parentView === x.id;
      });
    }
    return topView;
  }

  render() {
    const { styles, user } = this.props;
    const { search } = this.state;
    const activeView = this.getActiveView();
    const viewChain = this.getViewChain();

    return (
      <div className="row m-0 p-0">
        <div className="container-fluid" style={{ padding: '0px' }}>
          <Branding text="Script Coverage" />

          <Container style={{ margin: '0px', paddingRight: '0px' }} className="col-md-12">
            <Row style={{ width: '100%' }}>
              <Col style={{ padding: '0px' }}>
                {activeView.back && (
                  <div style={{ marginTop: '10px' }}>
                    <Link style={{ color: '#000', marginLeft: '20px' }} to={{ seawrch: `?view=${activeView.back}` }}>
                      &lt;&lt; Back
                    </Link>
                  </div>
                )}
                <Breadcrumb style={{ visibility: 'hidden' }}>
                  {viewChain.map(viewItem => {
                    return (
                      <BreadcrumbItem active={viewItem.id === activeView.id}>
                        {viewItem.id === activeView.id ? (
                          viewItem.fullText
                        ) : (
                          <Link
                            style={{ color: '#000' }}
                            to={{
                              search: `?view=${viewItem.id}${
                                viewItem.lookup != null ? '&' + viewItem.lookup + '=' + viewItem.fullText : ''
                              }`,
                            }}
                          >
                            {viewItem.fullText}
                          </Link>
                        )}
                      </BreadcrumbItem>
                    );
                  })}
                </Breadcrumb>
              </Col>
              {activeView.supportsSearch == true && (
                <Col className={styles.searchColumn}>{this.searchBar(styles, true, search)}</Col>
              )}
              <Col style={{ padding: '0px' }} className="float-right">
                {this.getNavigationMenu(styles, this.views, user, viewChain)}
              </Col>
            </Row>
            <Row />
            <Row style={{ marginRight: '15px', marginLeft: '0px' }}>
              <Col style={{ padding: '0px' }}>{activeView.container}</Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
  getNavigationMenu(styles, views, user, viewChain) {
    return (
      <div className="float-right">
        {views
          .filter(view => {
            return (
              view.parentView == null &&
              view.hidden != true &&
              !viewChain.some(vc => {
                return vc.id === view.id;
              }) &&
              hasAccess(view.requiredPermission)
            );
          })
          .map(view => {
            if (view.container == null) {
              //show menu
            } else {
              //navigate
              return (
                <Link to={{ search: `?view=${encodeURIComponent(view.id)}` }}>
                  <i
                    className={`fa ${view.icon} fa-2x`}
                    aria-hidden="true"
                    style={view.style != null ? view.style : {}}
                  />
                </Link>
              );
            }
          })}
        <OverlayTrigger
          trigger="click"
          placement="bottom"
          container={this}
          rootClose
          overlay={this.getProfileMenu(styles, views)}
          className="float-right"
        >
          <div
            className={`${styles.userImage} float-right`}
            style={{
              marginRight: '0px',
              backgroundImage: `url(${
                user && user.personId && user.personId.profile_pic ? user.personId.profile_pic : Avatar
              })`,
            }}
          />
        </OverlayTrigger>
      </div>
    );
  }
  getProfileMenu(styles, views) {
    const signOutOnClick = () => {
      localStorage.removeItem('uta.auth');
      localStorage.removeItem('scripts.authorization');
      window.location = '/login';
    };
    return (
      <Popover id="popover-positioned-bottom" title={'Menu'} className={styles.popoverTitle}>
        {this.popoverItem('Sign Out', 'fa-sign-out', signOutOnClick, styles)}
      </Popover>
    );
  }
  popoverItem(label, iconClass, onClick, styles) {
    const iconClassName = `${styles.menuIcon} fa fa-fw ${iconClass}`;

    return (
      <div className={styles.menuList}>
        <i className={iconClassName} aria-hidden="true" />
        <a onClick={onClick} className={styles.menuItem}>
          {label}
        </a>
      </div>
    );
  }
  searchBar(styles, noDebounce, search, navigate) {
    return (
      <div className={styles.search}>
        <Search
          noDebounce={noDebounce}
          value={search.query}
          onSubmit={query => {
            if (!query && this.props.onSearchChange) {
              this.props.onSearchChange(query);
              return;
            }
            if (navigate) {
              navigate({ ...search, query });
            }
          }}
          className={styles.searchContainer}
          inputClassName={styles.searchInput}
          onChange={value => {
            if (this.props.onSearchChange) {
              this.props.onSearchChange(value);
            }
          }}
          inputRef={searchInput => {
            this.searchInput = searchInput;
          }}
        />
      </div>
    );
  }
}

const withStyles = styled({
  menu: {
    margin: 0,
    padding: 0,
    height: '100vh',
    backgroundImage: `url(${leftMenuBackground})`,
  },
  navTop: {
    color: '#000',
    height: 35,
    width: 35,
  },
  content: {
    display: 'flex',
    fontSize: 10,
    fontWeight: '100',
  },
  arrowRight: {
    width: 0,
    height: 0,
    borderTop: '30px solid transparent',
    borderBottom: '30px solid transparent',
    borderLeft: '30px solid black',
    float: 'left',
  },
  userImage: {
    borderRadius: '50%',
    height: 30,
    width: 30,
    backgroundSize: 'cover',
    cursor: 'pointer',
    margin: '10px',
  },
  menuList: {
    fontSize: '0.5 em',
    fontWeight: 'normal',
    background: '#FFF',
    paddingLeft: '10px',
    textAlign: 'left',
    paddingRight: '10px',
  },
  menuItem: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  menuIcon: {
    marginRight: 10,
  },
  search: {
    width: '100%',
  },
  searchContainer: {
    margin: '5px',
  },
  searchInput: {
    border: `1px solid ${colors.border}`,
    background: colors.background,
  },
  searchColumn: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },
  popoverTitle: {
    opacity: 1,
    background: '#eee',
    textAlign: 'center',
    fontSize: '0.7 em',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginBottom: '4px',
  },
});

const withState = connect((store, ownProps) => {
  const personId = ownProps.match.params.personId;
  const { user, desk } = store.user;
  const { person } = store.profile;

  return { personId, person, user, desk };
});

export default withStyles(withState(Dashboard));
