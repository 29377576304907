import * as React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { styled, helpers } from 'react-free-style';
//import { Row, Card, CardText, CardBody, CardTitle, CardSubtitle, Button, Table } from 'reactstrap';
import * as colors from '../../../styles/colors';
import * as elements from '../../../styles/elements';
import * as icons from '../../../styles/icons';
import { Spinner, BasicCard, BasicInfo, Info, Input } from 'julius-frontend-components';
import { hasAccess } from '../../../support/auth';
import { searchCompany } from 'julius-frontend-store';
import { Container, Row, Col, Button, Form, FormGroup, Label, ListGroup, ListGroupItem } from 'reactstrap';
import { Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle } from 'reactstrap';

class Scripts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      type: '',
    };
  }

  componentWillMount() {
    this.loadDetails();
  }

  async loadDetails() {
    const { dispatch } = this.props;
    //dispatch(something);
  }

  render() {
    const { styles, script, saveChanges, onChange } = this.props;
    const list = [];
    return (
      <Container className={styles.container}>
        <Row className={styles.row}>
          <Col className={styles.col}>
            <Card className={styles.card}>
              <CardBody>
                <CardTitle className={styles.cardTitle}>Steven 'JAB' Jablonicky **Premium Role**</CardTitle>
                <Info name="Type">Lead</Info>
                <Info name="Gender">Male</Info>
                <Info name="Ethnicity">Any</Info>
                <Info name="Age">20</Info>
                <Info name="Description">
                  <span style={{ paddingLeft: '10px', display: 'block' }}>
                    Member of a military intel unit in the 2003 Iraq War. Described as, “sarcastic, witty, everything’s
                    ar joke type, but he can’t help but care”. Unlike his superiors, he doesn’t believe Saddam had a
                    functioning WMD program. Despite his views, he’s well-liked and trusted by his team.
                  </span>
                </Info>
              </CardBody>
            </Card>
          </Col>
          <Col className={styles.col}>
            <Card className={styles.card}>
              <CardBody>
                <CardTitle className={styles.cardTitle}>Master Sgt. Dwayne Clark</CardTitle>
                <Info name="Type">Supporting</Info>
                <Info name="Gender">Male</Info>
                <Info name="Ethnicity">African-American</Info>
                <Info name="Age">38</Info>
                <Info name="Description">
                  <span style={{ paddingLeft: '10px', display: 'block' }}>
                    Jab’s superior, he runs the intel unit. He’s a “barrel-chested, military poster boy, crass, booming
                    voice”. He wears hearing aids in both ears. Seems to be estranged from his wife/daughter.
                  </span>
                </Info>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className={styles.row}>
          <Col className={styles.col}>
            <Card className={styles.card}>
              <CardBody>
                <CardTitle className={styles.cardTitle}>Pvt. Jorge Velasco</CardTitle>
                <Info name="Type">Supporting</Info>
                <Info name="Gender">Male</Info>
                <Info name="Ethnicity">Mexican</Info>
                <Info name="Age">19</Info>
                <Info name="Description">
                  <span style={{ paddingLeft: '10px', display: 'block' }}>
                    Member of Jab’s unit, he’s a Mexican immigrant from the Bronx serving in the military to gain
                    citizenship. He’s, “super buff and a little too gung-ho Army”. He repeatedly second-guesses Jab’s
                    actions and often screws up the simplest tasks.
                  </span>
                </Info>
              </CardBody>
            </Card>
          </Col>
          <Col className={styles.col}>
            <Card className={styles.card}>
              <CardBody>
                <CardTitle className={styles.cardTitle}>Steven 'JAB' Jablonicky</CardTitle>
                <Info name="Type">Supporting</Info>
                <Info name="Gender">Female</Info>
                <Info name="Ethnicity">African-American</Info>
                <Info name="Age">23</Info>
                <Info name="Description">
                  <span style={{ paddingLeft: '10px', display: 'block' }}>
                    Member of Jab’s unit, she’s a “spirited, type A, loves being an intel analyst”. She’s vastly smarter
                    than anyone else in the intel unit.
                  </span>
                </Info>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const withStyles = styled({
  container: { margin: '0px', width: '100%' },
  row: { margin: '0px' },
  col: { margin: '0px' },
  card: {
    minWidth: 275,
    marginBottom: '10px',
  },
  cardTitle: {
    fontFamily: 'Gotham, sans-serif',
    fontSize: '12px',
    textTransform: 'uppercase',
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    color: colors.contentColor,
    fontSize: 12,
    textTransform: 'uppercase',
  },
  thText: {
    textAlign: 'left',
    padding: 10,
  },
  scriptButton: helpers.merge(elements.button, elements.actionable, {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderColor: '#000',
    paddingTop: '0px',
    paddingBottom: '0px',
    height: '30px',
  }),
  scriptButtonIcon: helpers.merge(
    {
      marginRight: 10,
    },
    icons.plus
  ),
  buttonText: {
    width: '60px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const withState = connect(state => {
  return {};
});

export default withRouter(withState(withStyles(Scripts)));
