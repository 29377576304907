import * as React from 'react';
import { Info, Input, TextArea, Select } from 'julius-frontend-components';
import { Button, Card, CardBody, CardTitle } from 'reactstrap';

export default class Charaacter extends React.Component {
  constructor(props) {
    super(props);
  }
  types = ['Lead', 'Supporting', 'Major Cameo', 'Brief Cameo'];
  genders = ['Any', 'Male', 'Female', 'Other'];
  ethnicities = [
    'Any',
    'Black',
    'Asian',
    'Hispanic / Latino',
    'Middle Eastern',
    'Native American',
    'Southeast Asian',
    'White',
  ];
  onChange() {
    this.props.onChange != null && this.props.onChange();
  }
  render() {
    const { role, editMode, styles } = this.props;

    return (
      <Card className={styles.card}>
        {editMode ? (
          <CardBody>
            <Input
              title="Character Name"
              value={role.character}
              onChange={text => {
                role.character = text;
                this.onChange();
              }}
            />
            <Select
              title="Role Type"
              children={role.type}
              items={this.types.map(t => {
                return {
                  key: t,
                  content: t,
                  active: false,
                  onClick: () => {
                    role.type = t;
                    this.onChange();
                  },
                };
              })}
            />
            <Select
              title="Gender"
              children={role.gender}
              items={this.genders.map(t => {
                return {
                  key: t,
                  content: t,
                  active: false,
                  onClick: () => {
                    role.gender = t;
                    this.onChange();
                  },
                };
              })}
            />
            <Select
              title="Ethnicity"
              children={role.ethnicity}
              items={this.ethnicities.map(t => {
                return {
                  key: t,
                  content: t,
                  active: false,
                  onClick: () => {
                    role.ethnicity = t;
                    this.onChange();
                  },
                };
              })}
            />
            <Input
              title="Age"
              value={role.age}
              onChange={text => {
                role.age = text;
                this.onChange();
              }}
            />
            <TextArea
              title="Description"
              onChange={text => {
                role.description = text;
                this.onChange();
              }}
            >
              {role.description}
            </TextArea>
          </CardBody>
        ) : (
          <CardBody>
            <CardTitle className={styles.cardTitle}>
              {role.character} {role.premium && '**Premium Role**'}
            </CardTitle>
            <Info name="Type">{role.type}</Info>
            <Info name="Gender">{role.gender}</Info>
            <Info name="Ethnicity">{role.ethnicity}</Info>
            <Info name="Age">{role.age}</Info>
            <Info name="Description">
              <span style={{ paddingLeft: '10px', display: 'block' }}>{role.description}</span>
            </Info>
          </CardBody>
        )}
      </Card>
    );
  }
}
