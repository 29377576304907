import * as React from 'react';

import { styled } from 'react-free-style';
import { Info, Select, TextArea, Input } from 'julius-frontend-components';
import ButtonSelector from './button-selector';
import { Card, CardBody, CardTitle } from 'reactstrap';

class CoverageReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      type: '',
    };
  }
  budgets = ['Low', 'Medium', 'High'];
  genres = [
    'Action',
    'Biopic',
    'Comedy',
    'Coming of Age',
    'Crime',
    'Drama',
    'Espionage',
    'Fantasy',
    'Found Footage',
    'Heist',
    'Horror',
    'Kids/Family',
    'Parody',
    'Romantic Comedy',
    'SCI-FI',
    'Supernatural',
    'Suspense',
    'Teen Comedy',
    'Thriller',
    'True Life',
    'Western',
  ];
  getRatingSelector(element, item) {
    const ratings = [1, 2, 3, 4, 5];
    return <ButtonSelector values={ratings} targetObject={element} targetField={item} />;
  }
  getRecommendationSelector(title, element, item, onChange) {
    const recommendations = ['Pass', 'Weak Consider', 'Consider', 'Strong Consider', 'Recommend', 'Strong Recommend'];
    return this.getSelector(title, recommendations, element, item, onChange);
  }
  getSelector(title, values, element, item, onChange) {
    return (
      <Select
        title={title || item}
        children={element[item]}
        items={values.map(r => {
          return {
            key: r,
            content: r,
            active: false,
            onClick: () => {
              element[item] = r;
              onChange == null && this.setState({});
              onChange != null && onChange();
            },
          };
        })}
      />
    );
  }

  render() {
    const { styles, assignment, onChange } = this.props;
    return (
      <Card className={styles.card}>
        <CardBody>
          <CardTitle className={styles.cardTitle}>Report</CardTitle>
          <Info name="Budget">
            <ButtonSelector values={this.budgets} targetObject={assignment} targetField="budget" />
          </Info>
          {this.getSelector('Genre', this.genres, assignment, 'genre')}
          <Input
            title="Period"
            value={assignment.period}
            onChange={text => {
              assignment.period = text;
            }}
          />
          <Input
            title="Location"
            value={assignment.location}
            onChange={text => {
              assignment.location = text;
            }}
          />
          <TextArea
            title="Overall Impressions"
            onChange={text => {
              assignment.overallImpressions = text;
            }}
          >
            {assignment.overallImpressions}
          </TextArea>
          <Input
            title="Tags"
            value={assignment.tags}
            onChange={text => {
              assignment.tags = text;
            }}
          />
          <Input
            title="Log Line"
            value={assignment.logLine}
            onChange={text => {
              assignment.logLine = text;
            }}
          />
          <TextArea
            title="Blurb"
            onChange={text => {
              assignment.blurb = text;
            }}
          >
            {assignment.blurb}
          </TextArea>
          <TextArea
            title="Synopsis"
            onChange={text => {
              assignment.synopsis = text;
            }}
          >
            {assignment.synopsis}
          </TextArea>
          <TextArea
            title="Comments"
            onChange={text => {
              assignment.comments = text;
            }}
          >
            {assignment.comments}
          </TextArea>
        </CardBody>
      </Card>
    );
  }
}
const withStyles = styled({
  card: {
    minWidth: 275,
    marginBottom: '10px',
  },
  cardTitle: {
    fontFamily: 'Gotham, sans-serif',
    fontSize: '12px',
    textTransform: 'uppercase',
  },
});
export default withStyles(CoverageReport);
