import * as React from 'react';
import { styled } from 'react-free-style';

import { Info, Input, Select, CompanyInput } from 'julius-frontend-components';

import scriptFormItems from '../../support/items/script-form-items';

class ScriptSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      type: '',
      studioSearchText: '',
      prodSearchText: '',
    };
  }

  setSearchText(searchText) {
    this.props.onSearch != null && this.props.onSearch(searchText);
    this.setState({ searchText });
  }
  setStudioSearchText(searchText) {
    this.props.onStudioSearch != null && this.props.onStudioSearch(searchText);
    this.setState({ studioSearchText: searchText });
  }
  setProdSearchText(searchText) {
    this.props.onProdSearch != null && this.props.onProdSearch(searchText);
    this.setState({ prodSearchText: searchText });
  }
  onSelect(script) {
    this.setSearchText('');
    this.props.onSelect != null && this.props.onSelect(script);
  }
  onStudioSelect(company) {
    this.props.script.studio = company;
    this.setStudioSearchText('');
  }
  onProdSelect(company) {
    this.props.script.productionCo = company;
    this.setProdSearchText('');
  }
  setVersion(version) {
    this.props.onVersionChange && this.props.onVersionChange(version);
  }
  render() {
    const {
      styles,
      script,
      canEdit,
      searchResults,
      studioSearchResults,
      prodSearchResults,
      version,
      scriptVersions,
      onSaveScriptVersion,
      onCancelScriptVersion,
    } = this.props;
    const { searchText, studioSearchText, prodSearchText } = this.state;
    return (
      <div>
        {script == null && (
          <div>
            <Input
              title="Search"
              onChange={text => {
                this.setSearchText(text);
              }}
            />

            {searchText.trim() !== '' && (
              <div style={{ maxHeight: '100px', overflow: 'scroll', overflowX: 'hidden', border: '1px solid #AAA' }}>
                <div
                  className={styles.item}
                  onClick={() => {
                    this.onSelect({ title: searchText, versions: [{ version: 1 }] });
                  }}
                >
                  {`New Script: ${searchText}`}{' '}
                </div>
                {searchResults != null &&
                  searchResults.map(result => {
                    return (
                      <div
                        className={styles.item}
                        onClick={() => {
                          this.onSelect(result);
                        }}
                      >
                        {result.title}
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        )}
        {script && (
          <div>
            {canEdit && (
              <a
                onClick={() => {
                  this.onSelect(null);
                }}
                style={{ cursor: 'pointer', fontSize: '0.7em' }}
              >
                &lt;- Select Different Script
              </a>
            )}
            {script._id == null ? (
              <div>
                <Input
                  title="Title"
                  value={script.title}
                  onChange={text => {
                    script.title = text;
                  }}
                />
                <Input
                  title="Authors"
                  value={script.authors}
                  onChange={text => {
                    script.authors = text;
                  }}
                />
                <Select
                  title="Form"
                  children={script.form}
                  items={scriptFormItems.map(i => {
                    return {
                      key: i,
                      content: i,
                      active: false,
                      onClick: () => {
                        script.form = i;
                        this.onSelect(script);
                      },
                    };
                  })}
                />
                <CompanyInput
                  title="Studio"
                  value={script.studio != null ? script.studio.name : studioSearchText}
                  onChange={text => {
                    this.setStudioSearchText(text);
                  }}
                  results={studioSearchText == '' ? null : studioSearchResults}
                  onSelect={company => {
                    this.onStudioSelect(company);
                  }}
                />
                <CompanyInput
                  title="Production Company"
                  value={script.productionCo != null ? script.productionCo.name : prodSearchText}
                  onChange={text => {
                    this.setProdSearchText(text);
                  }}
                  results={prodSearchText == '' ? null : prodSearchResults}
                  onSelect={company => {
                    this.onProdSelect(company);
                  }}
                />
                <input
                  type="checkbox"
                  id="clientMaterial"
                  checked={script.clientMaterial}
                  onChange={() => {
                    script.clientMaterial = !script.clientMaterial;
                    this.setState({});
                  }}
                  style={{ paddingLeft: '6px' }}
                />{' '}
                <label style={{ fontSize: '0.7em', color: script.clientMaterial ? '#000' : '#AAA' }}>
                  Client Material
                </label>
              </div>
            ) : (
              <div>
                <Info name="Title">{script.title}</Info>
                <Info name="Authors">{script.authors}</Info>
                <Info name="Form">{script.form}</Info>
                <Info name="Studio">{script.studio && script.studio.name}</Info>
                <Info name="Production Company">{script.productionCo && script.productionCo.name}</Info>
                <Info name="Client Material">{script.clientMaterial ? 'Yes' : 'No'}</Info>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
const withStyles = styled({
  item: {
    '&:hover': {
      backgroundColor: '#F9F9F9',
    },
    cursor: 'pointer',
    paddingLeft: '8px',
    borderBottom: '1px solid #AAA',
  },
  companyDiv: {
    flex: 1,
    width: '245px',
    paddingBottom: 6,
    paddingTop: 6,
    marginLeft: '-6px',
    display: 'flex',
    position: 'absolute',
    border: '1px solid #a8a8a8',
    borderTop: '0px solid #fff',
    backgroundColor: '#f5f5f5',
    marginTop: 20,
  },
});

export default withStyles(ScriptSelector);
