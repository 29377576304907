import * as React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { styled, helpers } from 'react-free-style';
//import { Row, Card, CardText, CardBody, CardTitle, CardSubtitle, Button, Table } from 'reactstrap';
import * as colors from '../../../styles/colors';
import * as elements from '../../../styles/elements';
import * as icons from '../../../styles/icons';
import { Spinner, Info } from 'julius-frontend-components';
import { hasAccess } from '../../../support/auth';
import { Container, Row, Col, Button, Form, FormGroup, Label, ListGroup, ListGroupItem } from 'reactstrap';
import { Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle } from 'reactstrap';

class Coverage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      type: '',
    };
  }

  componentWillMount() {
    this.loadDetails();
  }

  async loadDetails() {
    const { dispatch } = this.props;
    //dispatch(something);
  }

  render() {
    const { styles } = this.props;
    const list = [];
    return (
      <Container className={styles.container}>
        <Row className={styles.row}>
          <Col className={styles.col}>
            <Card className={styles.card}>
              <CardBody>
                <CardTitle className={styles.cardTitle}>Readers</CardTitle>
                <Info name="scriptreader23@gmail.com">
                  Assigned: 12/15/1966
                  <br />
                  Started: 12/17/1966 <br />
                  Completed: 12/23/1966
                </Info>
                <Info name="johndoe@yahoo.com">
                  Assigned: 12/23/1966
                  <br />
                  Started: 1/17/1967 <br />
                  Completed: 1/25/1967
                </Info>
              </CardBody>
            </Card>
            <Card className={styles.card}>
              <CardBody>
                <CardTitle className={styles.cardTitle}>Recommendation</CardTitle>
                <Info name="Script">WEAK CONSIDER</Info>
                <Info name="&nbsp;> Character">3/5</Info>
                <Info name="&nbsp;> Tone">1/5</Info>
                <Info name="&nbsp;> Dialog">2/5</Info>
                <Info name="&nbsp;> Visual Elements">3/5</Info>
                <Info name="Story">PASS</Info>
                <Info name="&nbsp;> Originality">2/5</Info>
                <Info name="&nbsp;> Structure">1/5</Info>
                <Info name="&nbsp;> Plotting">1/5</Info>
                <Info name="&nbsp;> Commerciality">3/5</Info>
                <Info name="Writer">PASS</Info>
                <Info name="&nbsp;>&nbsp;Comments">
                  <span style={{ display: 'block', paddingLeft: '10px' }}>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                    cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                  </span>
                </Info>
              </CardBody>
            </Card>
          </Col>
          <Col className={styles.col}>
            <Card className={styles.card}>
              <CardBody>
                <CardTitle className={styles.cardTitle}>Report</CardTitle>
                <Info name="Budget">Low</Info>
                <Info name="Genre">Comedy</Info>
                <Info name="Period">2005</Info>
                <Info name="Location">California</Info>
                <Info name="Overall Impressions">
                  <span style={{ display: 'block', paddingLeft: '10px' }}>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                    cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                  </span>
                </Info>
                <Info name="Tags">Lorem, Ipsum, Dolor</Info>
                <Info name="Log Line">Lorem ipsum dolor sit amet</Info>
                <Info name="Blurb">
                  <span style={{ display: 'block', paddingLeft: '10px' }}>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat.
                  </span>
                </Info>
                <Info name="Synopsis">
                  <span style={{ display: 'block', paddingLeft: '10px' }}>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                    cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                  </span>
                </Info>
                <Info name="Comments">
                  <span style={{ display: 'block', paddingLeft: '10px' }}>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                    cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                  </span>
                </Info>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const withStyles = styled({
  container: { margin: '0px', width: '100%' },
  row: { margin: '0px' },
  col: { margin: '0px' },
  card: {
    minWidth: 275,
    marginBottom: '10px',
  },
  cardTitle: {
    fontFamily: 'Gotham, sans-serif',
    fontSize: '12px',
    textTransform: 'uppercase',
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    color: colors.contentColor,
    fontSize: 12,
    textTransform: 'uppercase',
  },
  thText: {
    textAlign: 'left',
    padding: 10,
  },
  scriptButton: helpers.merge(elements.button, elements.actionable, {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderColor: '#000',
    paddingTop: '0px',
    paddingBottom: '0px',
    height: '30px',
  }),
  scriptButtonIcon: helpers.merge(
    {
      marginRight: 10,
    },
    icons.plus
  ),
  buttonText: {
    width: '60px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const withState = connect(state => {
  return {};
});

export default withRouter(withState(withStyles(Coverage)));
