const applicationName = 'scripts';
const authLocation = `${applicationName}.authorization`;
const parser = function(token) {
  if (token == null) {
    return {};
  }
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
};
export function getAuthToken() {
  return parser(localStorage.getItem(authLocation));
}
export function hasAccess(feature, scope) {
  const authToken = getAuthToken();
  return (
    authToken.features != null &&
    authToken.features.some(f => {
      return (
        f.application === applicationName &&
        f.feature === feature &&
        (scope == null ||
          f.scopes.some(s => {
            return s === scope;
          }))
      );
    })
  );
}
export function getScopes(feature) {
  const authToken = getAuthToken();

  const features = authToken.features.filter(f => {
    return f.application == applicationName && f.feature === feature;
  });
  const scopes = new Set();
  features.forEach(f => {
    if (f.scopes !== null) {
      f.scopes.forEach(s => {
        scopes.add(s);
      });
    }
  });
  return scopes;
}
