import * as React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { styled, helpers } from 'react-free-style';
//import { Row, Card, CardText, CardBody, CardTitle, CardSubtitle, Button, Table } from 'reactstrap';
import * as colors from '../../../styles/colors';
import * as elements from '../../../styles/elements';
import * as icons from '../../../styles/icons';
import { Spinner, Info, Select, TextArea, Input } from 'julius-frontend-components';

import { getReaderResources } from 'julius-frontend-store';

import { hasAccess } from '../../../support/auth';
import { Container, Row, Col, Button, Form, FormGroup, Label, ListGroup, ListGroupItem } from 'reactstrap';
import { Card, CardImg, CardText, CardBody, CardTitle } from 'reactstrap';

class ReaderAssignment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      type: '',
      resources: [],
      assignment: {
        script: { score: null, character: null, tone: null, dialog: null, visual: null },
        story: { score: null, originality: null, structure: null, plotting: null, commerciality: null },
        writer: { score: null, comments: null },
        budget: null,
        genre: null,
        period: null,
        location: null,
        reader: {},
      },
    };
  }
  componentWillMount() {
    this.loadDetails();
  }

  async loadDetails() {}

  render() {
    const { styles, resources, assignment, onChange } = this.props;
    const editMode = assignment.started == null;
    return (
      <Container className={styles.container}>
        <Row className={styles.row}>
          <Col>
            {editMode ? (
              <Card className={styles.card}>
                <CardBody>
                  <CardTitle className={styles.cardTitle}>Reader Information</CardTitle>

                  <Input
                    title="Name"
                    value={assignment.reader.name}
                    onChange={text => {
                      assignment.reader.name = text;
                      onChange && onChange();
                    }}
                  />
                  <Input
                    title="Email"
                    value={assignment.reader.email}
                    onChange={text => {
                      assignment.reader.email = text;
                      onChange && onChange();
                    }}
                  />
                </CardBody>
              </Card>
            ) : (
              <Card className={styles.card}>
                <CardBody>
                  <CardTitle className={styles.cardTitle}>Assignment Information</CardTitle>
                  <Info name="Reader">{assignment.reader.name}</Info>
                  <Info name="Email">{assignment.reader.email}</Info>
                  <Info name="Started">{assignment.started.toLocaleDateString()}</Info>
                  <Info name="Completed">
                    {assignment.completed ? assignment.completed.toLocaleDateString() : 'In Progress'}
                  </Info>
                </CardBody>
              </Card>
            )}
          </Col>
          <Col className={styles.col}>
            {resources != null && resources.length > 0 ? (
              <Card className={styles.card}>
                <CardBody>
                  <CardTitle className={styles.cardTitle}>Resources</CardTitle>
                  {resources.map(resource => {
                    return (
                      <Info
                        name={
                          <a className={styles.link} href={resource.url} target="_blank">
                            {resource.title}
                          </a>
                        }
                      />
                    );
                  })}
                </CardBody>
              </Card>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

const withStyles = styled({
  container: { margin: '0px', width: '100%' },
  row: { margin: '0px' },
  col: { margin: '0px' },
  card: {
    minWidth: 275,
    marginBottom: '10px',
  },
  cardTitle: {
    fontFamily: 'Gotham, sans-serif',
    fontSize: '12px',
    textTransform: 'uppercase',
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    color: colors.contentColor,
    fontSize: 12,
    textTransform: 'uppercase',
  },
  thText: {
    textAlign: 'left',
    padding: 10,
  },
  scriptButton: helpers.merge(elements.button, elements.actionable, {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderColor: '#000',
    paddingTop: '0px',
    paddingBottom: '0px',
    height: '30px',
  }),
  scriptButtonIcon: helpers.merge(
    {
      marginRight: 10,
    },
    icons.plus
  ),
  buttonText: {
    width: '60px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const withState = connect(state => {
  return {};
});

export default withRouter(withState(withStyles(ReaderAssignment)));
