import * as React from 'react';
import { PersonInput, Info } from 'julius-frontend-components';
import { Button } from 'reactstrap';

export default class AttachmentSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
    };
  }
  onSelect(person) {
    this.props.onSelect != null && this.props.onSelect(person);
    this.onChange('');
  }
  onChange(searchText) {
    this.setState({ searchText });
    this.props.onChange != null && this.props.onChange(searchText);
  }
  render() {
    const { searchResults, showPersonal, attachments, editMode } = this.props;
    const { searchText, hasFocus } = this.state;

    return (
      <div>
        {editMode && (
          <PersonInput
            inputRef={el => {
              this.nameInput = el;
            }}
            results={searchResults}
            value={searchText}
            onChange={value => {
              this.onChange(value);
            }}
            showPersonal={showPersonal}
            onSelect={person => {
              this.onSelect(person);
            }}
            focus={hasFocus}
            notRequired={true}
            onCancel={() => {
              this.setState({ hasFocus: false });
            }}
            onFocus={() => {
              this.setState({ hasFocus: true });
            }}
          />
        )}
        {attachments != null && attachments.length > 0
          ? attachments.map(attachment => {
              const roles = [];
              attachment.director && roles.push('Director');
              attachment.producer && roles.push('Producer');
              attachment.talent && roles.push('Talent');
              return (
                attachment != null && (
                  <Info
                    name={
                      <div>
                        {attachment.person != null && attachment.person.name}{' '}
                        {editMode && (
                          <a
                            style={{
                              cursor: 'pointer',
                              fontSize: '0.7em',
                              verticalAlign: 'super',
                              textDecoration: 'underline',
                            }}
                            onClick={() => {
                              const idToRemove = attachment._id ? attachment._id : attachment.person._id;
                              const indexToRemove = attachments.findIndex(a => {
                                return attachment._id ? idToRemove === a._id : idToRemove === a.person._id;
                              });
                              attachments.splice(indexToRemove, 1);
                              this.setState({});
                            }}
                          >
                            remove
                          </a>
                        )}
                      </div>
                    }
                  >
                    {editMode ? (
                      <div>
                        <Button
                          style={{ marginLeft: '2px', marginBottom: '2px' }}
                          size="sm"
                          outline={!attachment.director}
                          onClick={() => {
                            attachment.director = !attachment.director;
                            attachment.isDirty = true;
                            this.setState({});
                          }}
                        >
                          Director
                        </Button>
                        <Button
                          style={{ marginLeft: '2px', marginBottom: '2px' }}
                          size="sm"
                          outline={!attachment.producer}
                          onClick={() => {
                            attachment.producer = !attachment.producer;
                            attachment.isDirty = true;
                            this.setState({});
                          }}
                        >
                          Producer
                        </Button>
                        <Button
                          style={{ marginLeft: '2px', marginBottom: '2px' }}
                          size="sm"
                          outline={!attachment.talent}
                          onClick={() => {
                            attachment.talent = !attachment.talent;
                            attachment.isDirty = true;
                            this.setState({});
                          }}
                        >
                          Talent
                        </Button>
                      </div>
                    ) : (
                      roles.join(', ')
                    )}
                  </Info>
                )
              );
            })
          : ''}
      </div>
    );
  }
}
