import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { styled, helpers } from 'react-free-style';
import * as colors from '../../styles/colors';
import * as elements from '../../styles/elements';
import * as icons from '../../styles/icons';
import { Container, Row, Col, Button, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Spinner, AddButton, Info } from 'julius-frontend-components';
import { roleList } from 'julius-frontend-store';
import RoleDetailsView from './role-details';
import permissions from '../../support/permissions';
import { hasAccess } from '../../support/auth';

class AccessView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      type: '',
    };
  }

  componentWillMount() {
    this.loadList();
  }

  loadList() {
    const { dispatch } = this.props;
    const roleListFunc = roleList();
    dispatch(roleListFunc);
  }

  render() {
    const { styles, scripts } = this.props;

    const list = scripts.roleList;
    return (
      <Container style={{ width: '100%', margin: 0 }}>
        <Row>
          <Col>
            <Link to={{ search: `?view=roleDetails&role=new` }}>
              <button id="addScriptButton" className={styles.roleButton}>
                <span className={styles.buttonText}>
                  <i className={styles.roleButtonIcon} /> Add Role{' '}
                </span>
              </button>
            </Link>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <table id={'accessTable'} className={styles.table}>
              <thead>
                <tr className={styles.th}>
                  <th className={styles.thText}>Role</th>
                  <th className={styles.thText}>AD Groups</th>
                  <th className={styles.thText}>Features</th>
                </tr>
              </thead>
              {list.length > 0 ? (
                <tbody>
                  {list.map(role => {
                    return (
                      <tr className={styles.tr} style={{ borderTop: '1px solid gray', verticalAlign: 'top' }}>
                        <td className={styles.td}>
                          <Link className={styles.link} to={{ search: `?view=roleDetails&role=${role.id}` }}>
                            {role.name}
                          </Link>
                        </td>
                        <td className={styles.td}>
                          {role.groups.map(group => {
                            return (
                              <div>
                                <div style={{ fontSize: '1em' }}>{group.displayName}</div>
                                <div style={{ fontSize: '0.8em', fontFamily: 'Courier New' }}>{group.objectId}</div>
                              </div>
                            );
                          })}
                        </td>
                        <td className={styles.td} style={{ paddingRight: 10 }}>
                          {role.permissions
                            .filter(permission => {
                              return permission.feature != null;
                            })
                            .map(permission => {
                              return (
                                <Info
                                  name={
                                    permissions.find(p => {
                                      return p.feature === permission.feature;
                                    }).display
                                  }
                                >
                                  {permission.scopes.map(s => {
                                    return <div>{s}</div>;
                                  })}
                                </Info>
                              );
                            })}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <tbody />
              )}
            </table>
          </Col>
        </Row>
      </Container>
    );
  }
}

const withStyles = styled({
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    color: colors.contentColor,
    fontSize: 12,
    textTransform: 'uppercase',
  },
  thText: {
    textAlign: 'left',
    paddingLeft: 10,
    paddingTop: 10,
  },
  link: { color: '#4A4A4A', textDecoration: 'underline' },
  td: {
    fontFamily: 'Gotham',
    fontSize: 11,
    fontWeight: 300,
    color: colors.text,
    paddingTop: 1,
    paddingBottom: 1,
    paddingLeft: '10px',
    backgroundColor: '#FFF',
    border: '1px solid #F5F5F5',
    borderBottom: '0px',
    borderTopColor: '#EBEBEB',
    '&:last-child': {
      borderBottom: `1px solid ${colors.border}`,
    },
    backgroundColor: colors.contentBackground,
    border: `1px solid ${colors.background}`,
    borderTopColor: colors.border,
    borderBottom: 0,
  },
  tdFocus: {
    backgroundColor: colors.focusBackground,
  },
  tdActive: styles => ({
    border: `1px solid ${colors.focusBorder}`,
    [`& + .${styles.td}`]: {
      borderTop: 0,
    },
  }),
  tr: {
    '&:hover': {
      '>td': {
        backgroundColor: '#F9F9F9',
      },
    },
  },
  roleButton: helpers.merge(elements.button, elements.actionable, {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderColor: '#000',
    paddingTop: '0px',
    paddingBottom: '0px',
    height: '30px',
  }),
  roleButtonIcon: helpers.merge(
    {
      marginRight: 10,
    },
    icons.plus
  ),
  buttonText: {
    width: '60px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const withState = connect(state => {
  const { scripts } = state;
  return { scripts };
});

export default withRouter(withState(withStyles(AccessView)));
