import * as React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { helpers, styled } from 'react-free-style';
import { withRouter } from 'react-router-dom';

import { CompanyInput, DateInput, EditCard, Info, Input, Select, TextArea } from 'julius-frontend-components';
import { scriptsList, searchCompany, searchPersonProject } from 'julius-frontend-store';

import * as elements from '../../../styles/elements';
import * as icons from '../../../styles/icons';
import AttachmentSelector from '../../components/attachment-selector';
import ScriptSelector from '../../components/script-selector';

class Submission extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      studioSearchResults: [],
      prodSearchResults: [],
      scriptSearchResults: [],
      tempRequest: {},
      pendingScript: {},
    };
  }

  render() {
    const {
      styles,
      onChange,
      desks,
      desk,
      dispatch,
      request,
      tempRequest,
      onSaveSubmissionInfo,
      onCancelSubmissionInfo,
      onSaveScript,
      onCancelScript,
      onSaveScriptVersion,
      onCancelScriptVersion,
      onSubmitRequest,
      onAssignRequest,
      onCompleteRequest,
      onSaveScriptAttachments,
      onCancelScriptAttachments,
    } = this.props;
    const {
      studioSearchResults,
      prodSearchResults,
      submittedForSearchText,
      submittedForSearchResults,
      scriptSearchResults,
    } = this.state;
    const opportunites = [];
    request.openDirecting && opportunites.push('Open Directing');
    request.openWriting && opportunites.push('Open Writing');
    request.openCasting && opportunites.push('Open Casting');
    const editMode = request._id == null;
    const isNew = request._id == null;
    const canSubmit =
      request._id != null &&
      request.needed != null &&
      request.level != null &&
      request.script != null &&
      request.scriptVersion != null;
    const canAssign = request.assignments != null && request.assignments.length > 0;
    const canComplete = request.coverage != null && request.coverage.pending == true;

    return (
      <Container className={styles.container}>
        <Row className={styles.row}>
          <Col className={styles.col}>
            <EditCard
              title="Submission Information"
              canEdit={true}
              canCancel={!isNew}
              saveChanges={null}
              className={styles.card}
              isEditing={isNew}
              onSave={() => {
                onSaveSubmissionInfo();
              }}
              onCancel={() => {
                onCancelSubmissionInfo();
              }}
            >
              <div mode="Edit">
                <Select
                  title="Submitting Office"
                  children={desks != null && tempRequest.desk != null ? tempRequest.desk.name : desk.name}
                  items={
                    desks != null &&
                    desks.map(desk => {
                      return {
                        key: desk._id,
                        content: desk.name,
                        className: styles.selectItem,
                        active: false,
                        onClick: () => {
                          tempRequest.desk = desk;
                          onChange();
                        },
                      };
                    })
                  }
                  className={styles.select}
                />
                <DateInput
                  title="Needed By"
                  date={tempRequest.needed && new Date(tempRequest.needed)}
                  onChange={date => {
                    tempRequest.needed = date;
                    this.props.onChange();
                  }}
                />
                <Select
                  title="Coverage Level"
                  children={tempRequest.level}
                  items={['Standard', 'Detailed', 'None'].map(i => {
                    return {
                      key: i,
                      content: i,
                      className: styles.selectItem,
                      active: false,
                      onClick: () => {
                        tempRequest.level = i;
                        onChange();
                      },
                    };
                  })}
                  className={styles.select}
                />
                <CompanyInput
                  title="Submitted for Client"
                  value={tempRequest.client != null ? tempRequest.client.name : submittedForSearchText}
                  onChange={text => {
                    this.setSubmittedForSearchText(text);
                  }}
                  results={submittedForSearchText == '' ? null : submittedForSearchResults}
                  onSelect={company => {
                    tempRequest.client = company;
                    this.setState({ submittedForSearchText: '' });
                  }}
                />
                <Select
                  title="Representation"
                  children={tempRequest.representation == null ? 'Not Sure' : tempRequest.representation ? 'Yes' : 'No'}
                  items={['Yes', 'No', 'Not Sure'].map(i => {
                    return {
                      key: i,
                      content: i,
                      className: styles.selectItem,
                      active: false,
                      onClick: () => {
                        //let r = Object.assign({}, this.state.request);
                        tempRequest.representation = i === 'Not Sure' ? null : i === 'Yes' ? true : false;
                        this.setState({});
                      },
                    };
                  })}
                  className={styles.select}
                />
                <Container>
                  <Row>
                    <Col style={{ paddingRight: '0px', maxWidth: '30px' }}>
                      <input
                        type="checkbox"
                        id="openDirecting"
                        checked={tempRequest.openDirecting}
                        onChange={() => {
                          //let r = Object.assign({}, this.state.request);
                          tempRequest.openDirecting = !tempRequest.openDirecting;
                          this.setState({});
                        }}
                        style={{ paddingLeft: '6px' }}
                      />
                    </Col>
                    <Col style={{ padding: '0px' }}>
                      <label style={{ fontSize: '0.7em', color: tempRequest.openDirecting ? '#000' : '#AAA' }}>
                        Open Directing
                      </label>
                    </Col>
                    <Col style={{ paddingRight: '0px', maxWidth: '30px' }}>
                      <input
                        type="checkbox"
                        id="openWriting"
                        checked={tempRequest.openWriting}
                        onChange={() => {
                          //let r = Object.assign({}, this.state.request);
                          tempRequest.openWriting = !tempRequest.openWriting;
                          this.setState({});
                        }}
                        style={{ paddingLeft: '6px' }}
                      />
                    </Col>
                    <Col style={{ padding: '0px' }}>
                      <label style={{ fontSize: '0.7em', color: tempRequest.openWriting ? '#000' : '#AAA' }}>
                        Open Writing
                      </label>
                    </Col>
                    <Col style={{ paddingRight: '0px', maxWidth: '30px' }}>
                      <input
                        type="checkbox"
                        id="openCasting"
                        checked={tempRequest.openCasting}
                        onChange={() => {
                          //let r = Object.assign({}, this.state.request);
                          tempRequest.openCasting = !tempRequest.openCasting;
                          this.setState({});
                        }}
                        style={{ paddingLeft: '6px' }}
                      />
                    </Col>
                    <Col style={{ padding: '0px' }}>
                      <label style={{ fontSize: '0.7em', color: tempRequest.openCasting ? '#000' : '#AAA' }}>
                        Open Casting
                      </label>
                    </Col>
                  </Row>
                </Container>
                <TextArea
                  title="Comments"
                  onChange={text => {
                    tempRequest.comments = text;
                  }}
                  value={tempRequest.comments}
                />
              </div>

              <div mode="Info">
                <Info name="Submitting Office">{request.desk && request.desk.name}</Info>

                <Info name="Needed By">{request.needed && new Date(request.needed).toLocaleDateString()}</Info>
                <Info name="Coverage Level">{request.level}</Info>
                <Info name="Submitted for Client">{request.client && request.client.name}</Info>
                <Info name="Representation">
                  {request.representation == null ? 'Not Sure' : request.representation ? 'Yes' : 'No'}
                </Info>
                <Info name="Opportunities">{opportunites.join(', ')}</Info>
                <Info name="Comments">
                  <span style={{ paddingLeft: '10px', display: 'block' }}>{request.comments}</span>
                </Info>
              </div>
            </EditCard>
            {request._id && (
              <EditCard title="Workflow" canEdit={false} canCancel={!isNew} className={styles.card} isEditing={false}>
                <Info name="Draft Date">{request.created && new Date(request.created).toLocaleDateString()}</Info>
                <Info name="Submission Date">
                  {request.submitted ? (
                    new Date(request.submitted).toLocaleDateString()
                  ) : (
                    <Button
                      disabled={!canSubmit}
                      size="sm"
                      onClick={() => {
                        onSubmitRequest && onSubmitRequest();
                      }}
                    >
                      Submit Request
                    </Button>
                  )}
                </Info>
                <Info name="Assigned Date">
                  {request.assigned ? (
                    new Date(request.assigned).toLocaleDateString()
                  ) : (
                    <Button
                      disabled={!canAssign}
                      size="sm"
                      onClick={() => {
                        onAssignRequest && onAssignRequest();
                      }}
                    >
                      Assign Coverage
                    </Button>
                  )}
                </Info>

                <Info name="Completed Date">
                  {request.completed && request.coverage.pending == null ? (
                    new Date(request.completed).toLocaleDateString()
                  ) : (
                    <Button
                      disabled={!canComplete}
                      size="sm"
                      onClick={() => {
                        onCompleteRequest && onCompleteRequest();
                      }}
                    >
                      Approve Coverage
                    </Button>
                  )}
                </Info>
                {request.completed && (
                  <Info name="Coverage Report">
                    <Button
                      size="sm"
                      onClick={() => {
                        const url = `${process.env.REACT_APP_API_SERVER}/script-coverage/requests/${
                          request._id
                        }/reports/coverage`;
                        window.location.assign(url);
                      }}
                    >
                      Download PDF
                    </Button>
                  </Info>
                )}
              </EditCard>
            )}
          </Col>
          <Col className={styles.col}>
            {!isNew && (
              <EditCard
                className={styles.card}
                title="Script"
                canEdit={!isNew}
                canCancel={tempRequest.script != null && tempRequest.script._id != null}
                saveChanges={null}
                isEditing={!isNew && request.script == null}
                onSave={onSaveScript}
                onCancel={onCancelScript}
              >
                <div mode="Edit">
                  <ScriptSelector
                    canEdit={true}
                    onSearch={text => {
                      dispatch(scriptsList(text)).then(result => {
                        this.setState({
                          scriptSearchResults: result.body,
                        });
                      });
                    }}
                    script={tempRequest.script}
                    onSelect={script => {
                      tempRequest.script = script;
                    }}
                    searchResults={scriptSearchResults}
                    onStudioSearch={text => {
                      dispatch(searchCompany(text)).then(result => {
                        this.setState({ studioSearchResults: result.body.data });
                      });
                    }}
                    studioSearchResults={studioSearchResults}
                    onProdSearch={text => {
                      dispatch(searchCompany(text)).then(result => {
                        this.setState({ prodSearchResults: result.body.data });
                      });
                    }}
                    prodSearchResults={prodSearchResults}
                  />
                </div>
                <div mode="Info">
                  <ScriptSelector canEdit={false} script={request.script} />
                </div>
              </EditCard>
            )}
            {request.script == null || request.script._id == null ? (
              undefined
            ) : (
              <RevisionCard
                onCancelScriptVersion={onCancelScriptVersion}
                onFileSelect={this.onFileSelect}
                onSaveScriptVersion={onSaveScriptVersion}
                onSetState={stateObject => this.setState(stateObject)}
                request={request}
                styles={styles}
                tempRequest={tempRequest}
              />
            )}
            {request.script == null || request.script._id == null ? (
              undefined
            ) : (
              <EditCard
                title="Attached"
                canEdit={true}
                canCancel={true}
                saveChanges={null}
                className={styles.card}
                isEditing={false}
                onSave={() => {
                  onSaveScriptAttachments();
                }}
                onCancel={() => {
                  onCancelScriptAttachments();
                }}
              >
                <div mode="Edit">
                  <AttachmentSelector
                    editMode={true}
                    onChange={text => {
                      dispatch(searchPersonProject(text)).then(results => {
                        if (results != null) {
                          this.setState({ attachmentSearchResults: results.body.data });
                        }
                      });
                    }}
                    searchResults={this.state.attachmentSearchResults}
                    onSelect={person => {
                      if (tempRequest.script.attachments == null) {
                        tempRequest.script.attachments = [];
                      }
                      tempRequest.script.attachments.push({ person });
                      this.setState({});
                    }}
                    attachments={tempRequest.script && tempRequest.script.attachments}
                  />
                </div>
                <div mode="Info">
                  <AttachmentSelector editMode={false} attachments={request.script && request.script.attachments} />
                </div>
              </EditCard>
            )}
          </Col>
        </Row>
      </Container>
    );
  }

  onFileSelect = event => {
    const tempRequest = this.state.tempRequest;
    tempRequest.scriptVersion.selectedFile = event.target.files[0];
    this.setState({
      tempRequest,
    });
  };

  setSubmittedForSearchText(searchText) {
    this.setState({ submittedForSearchText: searchText });
    this.props.dispatch(searchCompany(searchText)).then(result => {
      this.setState({ submittedForSearchResults: result.body.data });
    });
  }
}

const RevisionCard = props => {
  const { onCancelScriptVersion, onFileSelect, onSaveScriptVersion, onSetState, request, styles, tempRequest } = props;

  const versionItems = [
    {
      key: null,
      content: 'New Version',
      active: false,
      onClick: () => {
        tempRequest.scriptVersion = {
          version: tempRequest.script.versions.length + 1,
          pages: '',
        };
        onSetState({ tempRequest });
      },
    },
  ].concat(
    request.script.versions.map(v => {
      return {
        key: v._id,
        content: v.version,
        active: false,
        onClick: () => {
          tempRequest.scriptVersion = v;
        },
      };
    })
  );

  return (
    <EditCard
      title="Revision"
      canEdit={request.script != null}
      canCancel={request.scriptVersion != null}
      saveChanges={null}
      className={styles.card}
      isEditing={request.scriptVersion == null}
      onSave={() => {
        onSaveScriptVersion();
      }}
      onCancel={() => {
        onCancelScriptVersion();
      }}
    >
      <div mode="Edit">
        {request.script != null && request.script.versions != null && request.script.versions.length > 0 ? (
          <Select
            title="Version"
            children={tempRequest.scriptVersion != null && tempRequest.scriptVersion.version}
            items={versionItems}
          />
        ) : null}
        <Input
          title="Version Name"
          value={tempRequest.scriptVersion && tempRequest.scriptVersion.version}
          onChange={text => {
            if (tempRequest.scriptVersion == null) {
              tempRequest.scriptVersion = {};
            }
            tempRequest.scriptVersion.version = text;
          }}
        />
        <Input type="file" value="" onChangeUsingEvent={onFileSelect} />
        <Input
          title="Page Count"
          value={(tempRequest.scriptVersion && tempRequest.scriptVersion.pages) || ''}
          onChange={text => {
            if (tempRequest.scriptVersion == null) {
              tempRequest.scriptVersion = {};
            }
            tempRequest.scriptVersion.pages = text;
          }}
        />
      </div>
      <div mode="Info">
        <Info name="Version Name">{request.scriptVersion && request.scriptVersion.version}</Info>
        {request.scriptVersion && request.scriptVersion.file && (
          <Info name="File">{request.scriptVersion && request.scriptVersion.file}</Info>
        )}
        {request.scriptVersion && request.scriptVersion.file && (
          <Info name="Box Id">{request.scriptVersion && request.scriptVersion.boxFileId}</Info>
        )}
        <Info name="Page Count">{request.scriptVersion && request.scriptVersion.pages}</Info>
      </div>
    </EditCard>
  );
};

const withStyles = styled({
  container: { margin: '0px', width: '100%' },
  row: { margin: '0px' },
  col: { margin: '0px' },
  card: {
    minWidth: 275,
    marginBottom: '10px',
  },
  cardTitle: {
    fontFamily: 'Gotham, sans-serif',
    fontSize: '12px',
    textTransform: 'uppercase',
  },
  cardEdit: {
    float: 'right',
    fontFamily: 'Gotham, sans-serif',
    fontSize: '12px',
    textTransform: 'uppercase',
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  scriptButton: helpers.merge(elements.button, elements.actionable, {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderColor: '#000',
    paddingTop: '0px',
    paddingBottom: '0px',
    height: '30px',
  }),
  scriptButtonIcon: helpers.merge(
    {
      marginRight: 10,
    },
    icons.plus
  ),
  buttonText: {
    width: '60px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  select: {
    fontSize: '0.8em',
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
  },
  formContainer: {
    display: 'flex',
    marginLeft: 5,
    marginTop: 10,
  },
  selectItem: {
    fontSize: 14,
    fontWeight: 300,
    padding: 10,
  },
});

const withState = connect((store, ownProps) => {
  //const { scripts } = store;
  //const desks = store.user.user.deskIds;
  //const desk = store.user.desk || (desks != null && desks[0]);
  //return { scripts, desks, desk };
});

export default withRouter(withState(withStyles(Submission)));
