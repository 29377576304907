export const container = {
  width: '100%',
  maxWidth: '1000px',
  padding: '0 10px',
  margin: '0 auto',
};

export const mobileBreakpoint = '(max-width: 900px)';

const mediumBreakPoint = 1180;
const smallBreakPoint = 860;
export const mediumBreak = `(max-width: ${mediumBreakPoint}px)`;
export const smallBreak = `(max-width: ${smallBreakPoint}px)`;
